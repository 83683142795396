import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EnvelopeIcon as OutlineEnvelopeIcon,
  LockClosedIcon as OutlineLockClosedIcon,
} from "@heroicons/react/24/outline";
import {
  EnvelopeIcon as SolidEnvelopeIcon,
  LockClosedIcon as SolidLockClosedIcon,
} from "@heroicons/react/24/solid";
import { ILoginCredentials } from "../../types/interfaces/ILoginCredentials.interface";
import { authLogin, getCurrency, getMyDetails, signIn } from "../../services/auth.service";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/auth-config";
import { storageKeys } from "../../constants/storage.constants";
import { toast } from "react-toastify";
import { store } from "../../store/store";
import { setAuthUser, setCurrency } from "../../features/Auth Slice/authSlice";
import { useDispatch } from "react-redux";

const initFormCredentials = {
  email: "",
  password: "",
};
const initFormErrors = {
  emailError: "",
  passwordError: "",
  loginError: false,
};
const emailRegex = /^(?!.*\s)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
// /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /.{8,32}$/;
const loginErrorMessage = "Incorrect email or password";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [mailIconSolid, setMailIconSolid] = useState(false);
  const [lockIconSolid, setLockIconSolid] = useState(false);
  const [loginFormCrdentials, setLoginFormCredentials] =
    useState<ILoginCredentials>(initFormCredentials);
  const [loginFormErrors, setLoginFormErrors] = useState(initFormErrors);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setLoginFormCredentials((prev) => ({ ...prev, [name]: value }));
    setLoginFormErrors((prev) => ({ ...prev, loginError: false }));
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.target?.name === "email"
      ? setMailIconSolid(true)
      : setLockIconSolid(true);
    const key =
      event?.target?.name === "email" ? "emailError" : "passwordError";
    setLoginFormErrors((prev) => ({ ...prev, [key]: "", loginError: false }));
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.target?.name === "email"
      ? setMailIconSolid(false)
      : setLockIconSolid(false);
    const name = event?.target?.name;
    const key =
      event?.target?.name === "email" ? "emailError" : "passwordError";
    const errorMsg =
      name === "email"
        ? "Please enter a valid email address."
        : "Password should be at least 8 characters long.";
    const value = event?.target?.value;
    const regex = name === "email" ? emailRegex : passwordRegex;
    if (!value?.match(regex)) {
      setLoginFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
    }
  };

  const onSubmit = async () => {
    const res = await authLogin(loginFormCrdentials);
    if (res) {
      navigate("/");
      clearForm();
    } else setLoginFormErrors((prev) => ({ ...prev, loginError: true }));
  };

  const clearForm = () => {
    setLoginFormCredentials(initFormCredentials);
    setLoginFormErrors(initFormErrors);
  };

  const handleSubmitCheck = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loginFormErrors?.emailError || loginFormErrors?.passwordError) {
      return;
    } else onSubmit();
  };

  const submitHandlerMSAL = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      console.log("Login Scope:", loginRequest.scopes);  // Log the scope
      const token = await instance.loginPopup(loginRequest);
      localStorage.setItem(storageKeys?.acToken, token.accessToken);
      
      const data = await signIn( );
      if (data) {

        const currency: any = await getCurrency();
        // localStorage.setItem("currency", currency.settings.currencyCode);
        store.dispatch(setCurrency(currency.settings.currencyCode));
        if(data.userInfo.role ==="admin"){
          localStorage.setItem(storageKeys?.user, JSON.stringify(data.userInfo));
          localStorage.setItem("currency", currency.settings.currencyCode);

          dispatch(setAuthUser(data.userInfo)); // Dispatch action to store user in Redux
        navigate("/");}
        else{
          toast.error("You are Unauthorized");
          await instance.logoutRedirect();

        }
      
      } else {
        toast.error("You are Unauthorized to Access");
        await instance.logoutRedirect();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleRememberMe = (event: any) => {
    localStorage.setItem(storageKeys?.rememberMe, event?.target?.checked);
  };
  return (
    <>
      <div className="flex flex-row">
        <div className="basis-3/5 lg:block hidden">
          <div className="max-h-screen relative">
            <img
              className="h-screen w-full object-fill"
              src="/images/sign-in-img.png"
              alt="img"
            />
            <div className="absolute top-[111px] text-center w-full">
              <h2 className="text-5xl text-white font-semibold">
                Get yourself Managed
              </h2>
              <p className="text-xl text-[#D6D6D6] font-semibold font-asap">
                Welcome to Employees App
              </p>
            </div>
          </div>
        </div>
        <div className="basis-full lg:basis-2/5 rounded-r-md bg-[#FAFAFA]">
          <img
            className="py-[40px] lg:ps-[30px] px-[15px]"
            src="/images/EMPLOYEES.svg"
            alt="EMPLOYEES"
          />

          <img
            className="text-center mx-auto"
            src="/images/sign-in-logo.svg"
            alt="logo"
          />
          <div className="px-[30px]">
            <h2 className="text-[2rem] text-[#333] flex justify-center font-semibold py-7">
              Login to your account
            </h2>
            {/* <form
              onSubmit={(event) => {
                handleSubmitCheck(event);
              }}
            >
              <div className="border border-[#D6D6D6] rounded-lg bg-white">
                <div className="relative">
                  <input
                    className="placeholder:text-[#858585] bg-white w-full rounded-t-lg py-2 pl-[55px] pr-3 focus:outline-none text-lg mb-[2px] h-[56px]"
                    placeholder="Email ID"
                    name="email"
                    type="email"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                    }}
                    onFocus={(event) => {
                      handleFocus(event);
                    }}
                  />

                  {mailIconSolid ? (
                    <SolidEnvelopeIcon className="text-[#378EB5] w-6 h-6 absolute top-[17px] left-[20px] " />
                  ) : (
                    <OutlineEnvelopeIcon className="text-[#858585] w-6 h-6 absolute top-[17px] left-[20px] " />
                  )}
                </div>
                {loginFormErrors?.emailError && (
                  <p className="text-red-600 pl-5">
                    {loginFormErrors?.emailError}
                  </p>
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 435 2"
                  fill="none"
                >
                  <path d="M0.963074 1H434.037" stroke="#EBEBEB" />
                </svg>
                <div className="relative">
                  {" "}
                  <input
                    className="placeholder:text-[#858585] bg-white w-full rounded-b-lg py-2 pl-[55px] pr-3 focus:outline-none text-lg mt-[2px] h-[56px]"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                    }}
                    onFocus={(event) => {
                      handleFocus(event);
                    }}
                  />
                  {lockIconSolid ? (
                    <SolidLockClosedIcon className="text-[#378EB5] w-6 h-6 absolute top-[17px] left-[20px] " />
                  ) : (
                    <OutlineLockClosedIcon className="text-[#858585] w-6 h-6 absolute top-[17px] left-[20px] " />
                  )}
                </div>
                {loginFormErrors?.passwordError && (
                  <p className="text-red-600 pl-5">
                    {loginFormErrors?.passwordError}
                  </p>
                )}
              </div>
              {loginFormErrors?.loginError && (
                <p className="text-red-600 text-center">{loginErrorMessage}</p>
              )}
              <div className="flex justify-between items-center mt-[17px] mb-[25px]">
              <div className="flex items-center">
  <input
    id="default-checkbox"
    type="checkbox"
    className="w-5 h-5 text-[#378EB5] bg-[#378EB5] border-[#378EB5] rounded focus:ring-[#378EB5] dark:focus:ring-[#378EB5] dark:ring-offset-[#378EB5] focus:ring-2 dark:bg-[#378EB5] dark:border-[#378EB5]"
    onClick={(e) => handleRememberMe(e)}
  />
  <label
    htmlFor="default-checkbox"
    className="ms-2 text-base text-[#5C5C5C] font-semibold cursor-pointer"
  >
    Remember me
  </label>
</div>
                <button
                  className="text-[#378EB5] text-sm font-semibold"
                  type="button"
                >
                  Forgot Password
                </button>
              </div>
              <button
                // onClick={handleSignIn}
                className="text-base w-full btnprimary"
                type="submit"
              >
                Sign in
              </button></form> */}
            {/* <div className="flex items-center justify-between my-5">
              <hr className="text-[#D6D6D6]  w-full" />
              <span className="text-sm text-[#333] mx-3">Or</span>
              <hr className="text-[#D6D6D6] w-full" />
            </div> */}
            <button
              className="flex justify-center items-center bg-white border border-[#D6D6D6] rounded-lg px-3 py-[14px] w-full text-[#333] text-base font-semibold gap-2"
              onClick={(event) => submitHandlerMSAL(event)}
            >
              <img src="/images/Microsoft svg.svg" alt="Microsoft" />
              Continue with Microsoft
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
