import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import {
  CalendarDaysIcon,
  ClipboardDocumentListIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import EmpReportsItem from "../../components/EmpReports/EmpReportsItem";
import {
  fetchEligibleUserForBank,
  fetchJoinerLeaver,
  fetchReportList,
} from "../../services/empReports.service";
import { IEmpReports } from "../../types/interfaces/IEmpRports";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { formatYear } from "../../utils/dateFromatter";
import { IJoinerLeaver } from "../../types/interfaces/ILeaver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Salary } from "../../types/interfaces/ISalaryRecords.interface";
import ReportTable from "../../components/EmpReports/ReportTable";
import CertificatesAndLettersItems from "../../components/CertifictaesAndLetterItem/CertificatesAndLetterItem";
import CertificateTable from "../../components/CertifictaesAndLetterItem/CertificateTable";
import BankCertificate from "../../components/CertifictaesAndLetterItem/BankCertificate";
import JobCertificate from "../../components/CertifictaesAndLetterItem/JobCeritificate";
import { IBankUSers } from "../../types/interfaces/IBankUsers";
import Certificate from "../../components/CertifictaesAndLetterItem/Certificate";
import { fetchEligibleUserForJob } from "../../services/empReports.service";
import InternCertificate from "../../components/CertifictaesAndLetterItem/InterCertificates";

const CertificatesAndLetters = () => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [reqAgain, setReqAgain] = useState(false);
  const [userData, setUserData] = useState<IBankUSers[] | []>([]);
  const [userJobData, setUserJobData] = useState<IBankUSers[] | []>([]);

  const filterMaxDate = moment()
    .month(new Date().getMonth() - 1)
    .toDate();

  const [selectedReport, setSelectedReport] = useState("Experience Certificate");
  const [leaverData, setLeaverData] = useState<IJoinerLeaver>();

  const handleDownloadJoinerLeaverExcel = async () => {
    const res: any = await fetchJoinerLeaver(formatYear(selectedDate));
    if (res?.res) {
      const joiners = res.res.joiners || [];
      const leavers = res.res.leavers || [];

      const processRows = (data: any) => {
        return data.map((row: any, index: any) => {
          const { userId, ...rest } = row;
          return { "Sr. No": index + 1, ...rest };
        });
      };

      const joinersProcessed = processRows(joiners);
      const leaversProcessed = processRows(leavers);

      const joinersSheet = XLSX.utils.json_to_sheet(joinersProcessed);
      const leaversSheet = XLSX.utils.json_to_sheet(leaversProcessed);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, joinersSheet, "Joiners");
      XLSX.utils.book_append_sheet(workbook, leaversSheet, "Leavers");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = `JoinerLeaver-${formatYear(selectedDate)}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
    } else {
      alert("Something went wrong");
    }
  };

  const fetchData = async () => {
    const res = await fetchEligibleUserForBank();
    setUserData((res?.res as IBankUSers[]) || []);
  };
  const fetchJobData = async () => {
    const res = await fetchEligibleUserForJob();
    setUserJobData((res?.res as IBankUSers[]) || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(selectedReport, "jfjf");
  }, []);
  const handleClear = () => {
    setSelectedDate(undefined);
    setReqAgain(!reqAgain);
  };

  const dummyData = {
    reportList: [
      "Experience Certificate",
      "Employment Letter For Bank Account",
      "Employment Letter For Job",
      "Internship Letter",
    ],
  };

  return (
    <>
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div className="basis-full px-[15px] xl:px-[30px]">
          <EmployeeStatus
            heading="Certificates And Letters"
            leadText="Download Certificates And Letters of employee "
            StatusLogo={<NewspaperIcon className="w-9 h-9 text-[#33475F]" />}
            Subscription=""
            showFirst={""}
            showSecond={false}
            Request={``}
            PendingDate={""}
          />
          <div className="basis-full md:basis-2/3">
            <div className="flex flex-col md:flex-row mt-5 gap-3 xl:gap-5">
              <div className="basis-full md:basis-1/3">
                <CertificatesAndLettersItems
                  title="Certificates And Letters "
                  reportsData={dummyData}
                  selectedReport={selectedReport}
                  setSelectedReport={setSelectedReport}
                />
              </div>
              <div className="basis-full md:basis-2/3 relative">
                {selectedReport === "Employment Letter For Bank Account" ? (
                  <BankCertificate getUserWithEmail={() => null} />
                ) : selectedReport === "Internship Letter" ? (
                  <InternCertificate getUserWithEmail={() => null} />
                ) : selectedReport === "Employment Letter For Job" ? (
                  <JobCertificate getUserWithEmail={() => null} />
                ) : (
                  <CertificateTable getUserWithEmail={() => null} />
                )}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default CertificatesAndLetters;
