import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef } from "react";

interface Props {
  reason: string;
  onClose: () => void;
}

const ReasonPopOver: React.FC<Props> = ({ reason, onClose }) => {
  const [positionAbove, setPositionAbove] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevents the click event from propagating to parent components
  };

  useEffect(() => {
    const adjustPosition = () => {
      if (popoverRef.current) {
        const rect = popoverRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (rect.bottom > viewportHeight) {
          setPositionAbove(true);
        } else {
          setPositionAbove(false);
        }
      }
    };

    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    window.addEventListener("scroll", adjustPosition);

    return () => {
      window.removeEventListener("resize", adjustPosition);
      window.removeEventListener("scroll", adjustPosition);
    };
  }, []);

  return (
    <div
      ref={popoverRef}
      className={`absolute right-20 ${
        positionAbove ? "bottom-full mb-2" : "top-full mt-2"
      } overflow-y-auto z-[1] bg-white border border-gray-300 shadow-3xl rounded-md w-[200px] sm:w-[350px] lg:w-[460px]`}
      onClick={stopPropagation} // Prevents clicks from closing the popover
    >
      <div className="flex justify-between items-center px-4 py-3">
        <h3 className="text-base font-semibold text-[#171717]">Reason</h3>
        <button onClick={onClose}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>
      <hr className="w-full " />
      <div className="pb-2 py-2">
        <p className="text-justify Employees font-asap text-sm text-[#5C5C5C] font-normal px-4 pb-4 whitespace-normal overflow-y-scroll max-h-24 break-words">
          {reason}
        </p>
      </div>
    </div>
  );
};

export default ReasonPopOver;
