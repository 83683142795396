import React, { useEffect, useState } from "react";
import MonthPerformanceCard from "./MonthPerformanceCard";
import { IEvalByYear } from "../../types/interfaces/IEvalByYear";
import {
  formatDateMontYear,
  formatDateSlash,
  formatStarRating,
  monthOrder,
} from "../../utils/dateFromatter";
import { deleteEvaYearModal } from "../../services/performance.service";
import moment from "moment";

interface Props {
  evalYearData?: IEvalByYear;
  year: number;
  setYear: Function;
  setHistory: Function;
  getPerformanceData:Function;
  getEvalByYearUser:Function;
  getEmpEvalForm:Function;
  getTotalEmp:Function
}

const PerformanceHistory: React.FC<Props> = ({
  evalYearData,
  year,
  setYear,
  setHistory,
  getPerformanceData,
  getEvalByYearUser,
  getEmpEvalForm,
  getTotalEmp
}) => {
  const handleYearChange = (event: any) => {
    setYear(event.target.value);
  };

  const deleteResult = async (monthId: string) => {
    const res = await deleteEvaYearModal(monthId);
    if (res?.res) {
      await getPerformanceData()
      await getEvalByYearUser()
      await getEmpEvalForm()
      await getTotalEmp()
      
    }
  };
  const generateYears = () => {
    const currentYear = moment().year();
    return Array.from({ length: 11 }, (_, i) => currentYear - i);
  };


  return (
    <>
      <div className="flex sm:flex-row flex-col justify-between sm:items-end gap-3">
        <div>
          {evalYearData && evalYearData.evaluationsByYear && (
            <h2 className="text-[#333333] text-lg font-semibold">
              {evalYearData?.evaluationsByYear[0]?.UserName}
            </h2>
          )}
          {evalYearData && evalYearData.evaluationsByYear && (
            <p className="text-[10px] text-[#858585]">
              {evalYearData?.evaluationsByYear[0]?.UserDesignation}
            </p>
          )}
        </div>

        <form className="w-60">
          <select
            id="countries"
            className="bg-[#EBEBEB] border border-[#D6D6D6] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={year}
            onChange={handleYearChange}
          >
            {generateYears().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </form>
      </div>

      <hr className="my-4" />
      {evalYearData && evalYearData.evaluationsByYear?.length ? (
        <div className="Employees overflow-y-scroll h-[calc(100vh-500px)] lg:h-[calc(100vh-455px)]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-3">
            {evalYearData?.evaluationsByYear
              ?.sort((a, b) => monthOrder[a.Month] - monthOrder[b.Month])
              ?.map((monthData, index) => (
                <div key={index}>
                  <MonthPerformanceCard
                    history={monthData}
                    UserId={monthData.ID}
                    Rating={+monthData.StarRating.toFixed(2)||0}
                    isPercentage={monthData.Percentage}
                    createdAt={monthData.CreatedAt}
                    monthName={monthData.Month}
                    percentage={+formatStarRating(monthData.Percentage)}
                    monthId={monthData.ID}
                    deleteModal={() => deleteResult(monthData.ID)}
                    userName={monthData.UserName}
                    year={monthData.Year}
                    setHistory={setHistory}
                  />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="text-center flex flex-col justify-center h-full">
          <span className="text-center flex justify-center mb-3">
            <img src="/images/no-data.png" alt="Please Select any Employee" />
          </span>
          <p className="text-lg font-normal text-[#333333]">
            There is no data of this year
            {/* click on Add Employee button to add an employee */}
          </p>
        </div>
      )}
    </>
  );
};

export default PerformanceHistory;
