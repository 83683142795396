import React, { useEffect, useRef, useState } from "react";
import {
  ArrowRightOnRectangleIcon,
  ArrowUpTrayIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  EnvelopeOpenIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import TablePagination from "../pagination/TablePagination";
import ReactDatePicker from "react-datepicker";
import {
  CalendarDaysIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/solid";
import {
  exportAssetsCSV,
  fetchAllAssets,
  updateAssetReqStatus,
} from "../../services/assetRequisition.service";
import { formatDateSlash, formatDateSpace } from "../../utils/dateFromatter";
import { IAssetsReq, Asset } from "../../types/interfaces/IAssetsReq.interface";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import moment from "moment";
import AssetsPopOver from "../../widgets/AssetsPopOver";
import { scrollToTop } from "../../utils/tableScroller";
import {
  fetchSuggestionInfo,
  postSuggestion,
} from "../../services/suggestion.service";
import {
  ISuggestion,
  ISuggestionData,
  SuggestionData,
} from "../../types/ISuggestion";
import StarRatings from "react-star-ratings";
import SuggestionModal from "./SuggestionModal";
import { useNavigate } from "react-router-dom";
import CommentsPopOver from "./CommentsPopOver";
import { toast } from "react-toastify";
import SuggestionPopOver from "./SuggestionsPopOver";

interface Props {
  getSuggestionInfo: any;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  openPopoverAssetId: string | null;
  setOpenPopoverAssetId: (id: string | null) => void;
  selectedSuggestion: string | null;
  setSelectedSuggestion: (id: string | null) => void;
  fetchSuggestionData: Function;
}

const SuggestionTable: React.FC<Props> = ({
  getSuggestionInfo,
  openPopoverId,
  setOpenPopoverId,
  openPopoverAssetId,
  setOpenPopoverAssetId,
  selectedSuggestion,
  setSelectedSuggestion,
  fetchSuggestionData,
}) => {
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isApprovedModalOpen, setIsApprovedIsModalOpen] = useState(false);
  const [isCommitteeModalOpen, setIsCommitteeIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [suggestion, setSuggestion] = useState<ISuggestion[]>([]);
  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [clickedComment, setClickedComment] = useState<string | null>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<
    SuggestionData | undefined
  >();
  const [isListOpen, setIsListOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [leaveFrom, leaveTo] = dateRange?.length > 0 ? dateRange : [];
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const handleComments = (comments: string) => {
    setComments(comments);
  };

  const openModal = (event: any, record: SuggestionData) => {
    setIsApprovedIsModalOpen(true);
    setSelectedStatus("approved");
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
    console.log(record.suggestion.Name);
  };

  const closeModal = () => {
    setIsApprovedIsModalOpen(false);
    setSelectedStatus("");
  };
  const openRejectedModal = (event: any, record: SuggestionData) => {
    setIsRejectModalOpen(true);
    setSelectedStatus("rejected");
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeRejectedModal = () => {
    setIsRejectModalOpen(false);
    setSelectedStatus("");
  };
  const openCommitteeModal = (event: any, record: SuggestionData) => {
    setIsCommentModalOpen(true);
    setSelectedStatus("published");
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
    console.log(record.suggestion.Name);
  };

  const closeCommitteeModal = () => {
    setIsCommentModalOpen(false);
    setSelectedStatus("");
  };

  const toggleAssetsList = (record: SuggestionData) => {
    if (openPopoverAssetId === record.suggestion.ID) {
      setOpenPopoverAssetId(null);
    } else {
      setOpenPopoverAssetId(record.suggestion.ID);
    }
    setSelectedRecord(record);
    !isListOpen && isPopoverOpen && setIsPopoverOpen(false);
  };
  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate, suggestion]);

  const handlePost = async () => {
    const suggestionId = selectedRecord?.suggestion?.ID || "";

    const resp = await postSuggestion(suggestionId, selectedStatus);
    console.log("object", resp);
    if (resp) {
      if (selectedStatus === "approved") {
        console.log("ejjejeje");
        setIsApprovedIsModalOpen(false);
        setSelectedStatus("");
        toast.success("Suggestion Request Approved Successfully");

        // navigate("/emp-suggestion");
      }
      if (selectedStatus === "rejected") {
        setIsRejectModalOpen(false);
        setSelectedStatus("");
        toast.success("Suggestion Request Rejected Successfully");
      } else {
        setIsCommentModalOpen(false);
        setSelectedStatus("");
      }
      fetchSuggestionData();
    } else {
      console.log("error");
    }
  };
  // useEffect(() => {
  //   // handlePost();
  // }, [selectedStatus, getSuggestionInfo]);
  const handleCommentsClick = (id: string) => {
    setOpenPopoverId(openPopoverId === id ? null : id);
  };

  const handleSuggestionClick = (id: string) => {
    if (selectedSuggestion === id) setSelectedSuggestion(null);
    setSelectedSuggestion(selectedSuggestion === id ? null : id);
  };

  const filteredSuggestions = getSuggestionInfo?.filter((req: any) => {
    if (searchQuery) {
      return req?.originatorName?.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return true; // If there's no search query, include all suggestions
  });
  

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center md:justify-between mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6]  bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
        </div>

        {suggestion ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Suggestion</th>
                    <th>Comment</th>
                    <th>Rating</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSuggestions?.map((req: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.originatorName}</td>
                      <td data-cell="Title">{req?.suggestion.Title}</td>
                      <td className="text-justify" data-cell="Suggestion">
                        <div className="word-break max-w-[200px] relative">
                          <button
                            className="text-sm text-[#378EB5] font-semibold"
                            onClick={(e) => {
                              handleSuggestionClick(req.suggestion.ID);
                            }}
                          >
                            <EnvelopeOpenIcon className="text-#378EB5 w-5 h-5" />
                          </button>
                          {selectedSuggestion === req.suggestion.ID && (
                            <SuggestionPopOver
                              suggestion={req.suggestion.Description}
                              onClose={() => setSelectedSuggestion(null)}
                            />
                          )}
                        </div>
                      </td>
                      <td data-cell="Comment">
                        <button
                          className="text-sm text-[#378EB5] font-semibold"
                          onClick={() => handleCommentsClick(req.suggestion.ID)}
                        >
                          {req.suggestion.Ratings?.length > 0 ? (
                            <ChatBubbleOvalLeftEllipsisIcon className="text-#378EB5 w-5 h-5" />
                          ) : (
                            "--"
                          )}
                        </button>
                        {openPopoverId === req.suggestion.ID && (
                          <CommentsPopOver
                            comments={req.suggestion.Ratings}
                            onClose={() => setOpenPopoverId(null)}
                          />
                        )}
                      </td>
                      <td data-cell="Rating">
                        {req?.suggestion?.IsRated
                          ? `${req?.averageRating}/10`
                          : "--"}
                      </td>
                      <td data-cell="Date Applied">
                        {formatDateSpace(
                          moment(req?.suggestion.CreatedAt).format("DD/MM/YYYY")
                        )}
                      </td>
                      <td data-cell="Status">{req?.suggestion.Status}</td>
                      <td>
                        <div className="flex items-center justify-end gap-2">
                          <>
                            {req?.suggestion?.Status === "Approved" ? (
                              <>
                                <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase  hover:cursor-default ">
                                  Approved
                                </span>
                              </>
                            ) : req?.suggestion?.Status === "Rejected" ? (
                              <>
                                <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase  hover:cursor-default ">
                                  Rejected
                                </span>
                              </>
                            ) : req?.suggestion?.Status === "Published" &&
                              req?.suggestion?.IsRated ? (
                              <>
                                <CheckCircleIcon
                                  onClick={(event) => openModal(event, req)}
                                  className="text-[#23CDAF] w-5 h-5 hover:text-black cursor-pointer"
                                />

                                <XMarkIcon
                                  onClick={(event) =>
                                    openRejectedModal(event, req)
                                  }
                                  className="text-[#333333] w-5 h-5 hover:text-black cursor-pointer"
                                />
                              </>
                            ) : req?.suggestion?.Status === "Published" &&
                              req?.suggestion?.IsRated === false ? (
                              <>
                                <span className="bg-[#378EB5] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase hover:cursor-default ">
                                  Reviewing
                                </span>
                              </>
                            ) : (
                              <>
                                <CheckCircleIcon
                                  onClick={(event) => openModal(event, req)}
                                  className="text-[#23CDAF] w-5 h-5 hover:text-black cursor-pointer"
                                />
                                <XMarkIcon
                                  onClick={(event) =>
                                    openRejectedModal(event, req)
                                  }
                                  className="text-[#333333] w-5 h-5 hover:text-black cursor-pointer"
                                />
                                <PaperAirplaneIcon
                                  onClick={(event) =>
                                    openCommitteeModal(event, req)
                                  }
                                  className="text-[#378EB5] w-5 h-5 hover:text-black cursor-pointer"
                                />
                              </>
                            )}
                          </>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <SuggestionModal
              isOpen={isApprovedModalOpen}
              onClose={closeModal}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={() => handlePost()}
              title={"Approved"}
              description={"Are you sure you want to approve this suggestion"}
              btnTitle={"Approve"}
            />
            <SuggestionModal
              isOpen={isRejectModalOpen}
              onClose={closeRejectedModal}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={() => handlePost()}
              title={"Rejected"}
              description={"Are you sure you want to reject this suggestion"}
              btnTitle={"Reject"}
            />
            <SuggestionModal
              isOpen={isCommentModalOpen}
              onClose={closeCommitteeModal}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={() => handlePost()}
              title={"Send to Committee"}
              description={
                "Are you sure you want to send this suggestion to the committee"
              }
              btnTitle={"Send"}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Suggestion Request Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SuggestionTable;
