import React, { useState } from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Square3Stack3DIcon } from "@heroicons/react/24/solid";

interface Props {
  assetsInfo: any;
  isOpenMainList: boolean;
  setIsOpenMainList: (check: boolean) => void;
  totalAssestRequest: any;
}

const AssetStatus: React.FC<Props> = ({
  assetsInfo,
  isOpenMainList,
  setIsOpenMainList,
  totalAssestRequest
}) => {
  // const [isOpenMainList, setIsOpenMainList] = useState(false);
  const handleToggle = () => {
    setIsOpenMainList(!isOpenMainList);
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center px-3 xl:px-5 py-3 sm:py-5 lg:py-8 bg-white rounded-2xl gap-3">
        <div className="flex items-center gap-3">
          <div className="bg-[#F0F7FB] p-2 rounded-xl">
            <Square3Stack3DIcon className="w-9 h-9 text-[#33475F]" />
          </div>
          <div>
            <h3 className="text-xl sm:text-2xl font-semibold text-[#33475F]">
              Asset Requisition
            </h3>
            <p className="text-sm text-[#33475F]">
              Manage assets acquired by the employees
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
          <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-[8px] xl:px-4 w-72.5">
            <p className="text-sm font-semibold text-[#5C6C7F] ">
              Total Assets Provided
            </p>
            <div className="flex items-center gap-0.5 md:gap-2">
              {assetsInfo &&
                assetsInfo.totalAssetsProvided &&
                Object.keys(assetsInfo.totalAssetsProvided)
                  .slice(0, 3)
                  .map((key) => (
                    <span
                      key={key} // Add key prop
                      className="text-[10px] text-[#5C5C5C] bg-[#d6dadf] rounded-[30px] px-2 py-1 gap-1 xl:gap-2 flex items-center"
                    >
                      {key}{" "}
                      <span className="text-[#33475F]">
                        {assetsInfo.totalAssetsProvided[key]}
                      </span>
                    </span>
                  ))}
              <button onClick={handleToggle}>
                <EllipsisHorizontalIcon className="w-6 h-6 text-[#33475F]" />{" "}
              </button>
              {isOpenMainList && (
                <div className="absolute top-56 lg:top-40 mt-3 bg-white rounded-lg left-12 lg:left-auto lg:right-[330px] shadow-3xl p-3 w-[250px] z-50">
                  <h2 className="text-base font-bold text-[#333333] px-2 pb-2 ">
                    Assets
                  </h2>
                  <hr />
                  {assetsInfo &&
                  assetsInfo.totalAssetsProvided &&
                  Object.keys(assetsInfo.totalAssetsProvided).length > 0 ? (
                    <ul className="AssetsOrderList overflow-y-scroll h-40">
                      {Object.entries(assetsInfo.totalAssetsProvided).map(
                        ([key, value]) => (
                          <li
                            key={key}
                            className="p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg flex items-center justify-between"
                          >
                            <p className="text-sm text-[#333333]">{key}</p>
                            <span className="bg-[#33475F] rounded-full text-xs text-white w-6 h-6 flex items-center justify-center">
                              {value as string}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <ul className="AssetsOrderList overflow-y-scroll h-40 flex justify-center items-center">
                      <li className="p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg flex items-center justify-between">
                        <p className="text-sm text-[#333333]">Not Available</p>
                      </li>
                    </ul>
                  )}
                  {/* <li className="p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg flex items-center justify-between">
                      <p className="text-sm text-[#333333]">LED Screen</p>
                      <span className="bg-[#33475F] text-xs text-[#FFFFFF] p-1 rounded-full">
                        03
                      </span>
                    </li>
                    <li className="p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg flex items-center justify-between">
                      <p className="text-sm text-[#333333]">Laptop</p>
                      <span className="bg-[#33475F] text-xs text-[#FFFFFF] p-1 rounded-full">
                        08
                      </span>
                    </li> */}
                  {/* </ul> */}
                </div>
              )}
            </div>
          </div>

          <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-2 lg:px-4 w-64">
            <p className="text-sm font-semibold text-[#5C6C7F]">
              Pending Requests
            </p>
            <h3 className="text-base font-semibold text-[#33475F]">
              {totalAssestRequest ?? 0}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetStatus;
