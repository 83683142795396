import { IPlaySlipRoot } from "../types/interfaces/IPaySlips.interface";
import { api } from "../utils/axios";

export const fetchEmpPaySlips = async (
  month: string,
  year: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/paySlip/getAllRecordsOfPaySlips/${month}/${year}${
        search ? "?search=" + search : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const downloadPaySlip = async (
  userID: string,
  month: string,
  year: string
) => {
  try {
    const { data, ok } = await api.post(
      `/paySlip/createAndDownloadPaySlip/${userID}/${month}/${year}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const generatePaySlip = async (month: number, year: number) => {
  try {
    const { data, ok } = await api.post<IPlaySlipRoot>(
      `/paySlip/generatePaySlipsForAllEmployees/${month}/${year}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchAllEmpPaySlipsData = async (
  month?: string,
  year?: string,
  search?: string
) => {
  try {
    const url = `/paySlip/getPayroll${
      month && year ? `?month=${month}&year=${year}` : ""
    }${search ? `?search=${encodeURIComponent(search)}` : ""}`;

    const response = await api.get(url);

    if (response.status === 200) {
      return { res: response.data, err: null };
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
