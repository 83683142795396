import React from "react";

import Login from "../../components/login/Login";

const SignIn = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default SignIn;
