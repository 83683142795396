import moment from "moment";

export const formatDateSpace = (date: string | Date) => {
  return moment.utc(date, "DD/MM/YYYY").local().format("DD MMM YYYY");
};

export const formatDateSlash = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};


export const formatDateMontYear = (month: string | number, year: string) => {
  return moment
    .utc()
    .local()
    .month(+month - 1)
    .year(+year)
    .format("MMMM YYYY");
};

export const formatDateMonth = (date: string | Date) => {
  return moment.utc(date).local().format("MM");
};
export const formatDateMonthFull = (date: string | Date) => {
  return moment.utc(date).local().format("MMMM");
};
export const formatDateYear = (date: string | Date) => {
  return moment.utc(date).local().format("YYYY");
};
export const formatYear = (date: string | Date) => {
  return parseInt(moment(date).format("YYYY"), 10);
};

export const formatStarRating = (starRating: number | any): string => {
  if (typeof starRating !== "number") {
    return "";
  }

  const roundedStarRating = Math.round(starRating * 10) / 10;
  console.log("object", typeof roundedStarRating.toFixed(1));
  return roundedStarRating.toFixed(1);
};
export const monthOrder: { [key: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const formatDate = (date: any) => {
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear().toString();

  return { month, year };
};

export const formatAmount = (amount: any) => {
  return Intl.NumberFormat('en-us',{
    maximumSignificantDigits:10,

  }).format(amount)
  // return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
