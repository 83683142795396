import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { fetchReportList } from "../../services/empReports.service";
import { IEmpReports } from "../../types/interfaces/IEmpRports";
import moment from "moment";

import { IJoinerLeaver } from "../../types/interfaces/ILeaver";
import { CurrencyDollarIcon, GifIcon } from "@heroicons/react/24/solid";
import BonusTable from "../../components/Table/BonusTable";
import {
  fetchAllAdvSalary,
  fetchSalariesCountInfo,
} from "../../services/advanceSalary.service";
import {
  ISalariesCountInfo,
  ISalaryRecords,
} from "../../types/interfaces/ISalaryRecords.interface";
import { fetchAllBonus } from "../../services/bonus.service";
import { allBonuses } from "../../types/interfaces/IBonus";

const BonusScreen = () => {
  const [salariesCountInfo, setSalariesCountInfo] =
    useState<ISalariesCountInfo>();
  const [pendingReq, setPendingReq] = useState<number>(0);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [bonus, setBonus] = useState<allBonuses>();
  const[totalBonus , setTotalBonus] = useState(0);
  // const getSalariesCountInfo = async () => {
  //   const res = await fetchSalariesCountInfo(moment().format("DD/MM/YYYY"));
  //   if (res?.res) {
  //     setSalariesCountInfo(res?.res as ISalariesCountInfo);
  //   }
  // };

  // useEffect(() => {
  //   getSalariesCountInfo();
  // }, []);

  // useEffect(() => {
  //   if (!salariesCountInfo?.TotalSalaries) return;
  //   setPendingReq(
  //     salariesCountInfo?.TotalSalaries - salariesCountInfo?.Approved
  //   );
  // }, [salariesCountInfo]);

  const getAllBonus = async (year?: string) => {
    const res : any= await fetchAllBonus(year);
    if (res?.res) {
      setBonus(res?.res as allBonuses);
      setTotalBonus(res.res.allBonuses.length)
    }
  };

  return (
    <div
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
      className="basis-full px-[15px] xl:px-[30px]"
    >
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
      <div className="">
        <EmployeeStatus
          heading="Bonus"
          leadText="See all Bonus"
          StatusLogo={<CurrencyDollarIcon className="w-9 h-9 text-[#33475F]" />}
          Subscription=""
          showFirst={false}
          showSecond={true}
          TotalSubscriptions={"Total Bonuses"}
          Total = {totalBonus}
          Request={``}
          PendingDate={""}
        />
        <div className="basis-2/3">
          <BonusTable
            openPopoverId={openPopoverId}
            setOpenPopoverId={setOpenPopoverId}
            advSalaryRequests={bonus}
            setAdvSalaryRequests={setBonus}
            getAllAdvSalary={getAllBonus}
          />
        </div>
      </div>
    </div>
  );
};

export default BonusScreen;
