import { api } from "../utils/axios";

export const fetchMedicalDetails = async (month: string, year: string) => {
  try {
    const { data, ok } = await api.get(
      `/bank/medicalAllowanceDetails?${month ? `month=${month}` : ""}${
        month && year ? "&" : ""
      }${year ? `year=${year}` : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchFuelDetails = async (month: string, year: string) => {
  try {
    const { data, ok } = await api.get(
      `/bank/fuelDetails?${month ? `month=${month}` : ""}${
        month && year ? "&" : ""
      }${year ? `year=${year}` : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchSalaryDetails = async (month: string, year: string) => {
  try {
    const { data, ok } = await api.get(
      `/bank/salaryDetails?${month ? `month=${month}` : ""}${
        month && year ? "&" : ""
      }${year ? `year=${year}` : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchSchoolingDetails = async (month: string, year: string) => {
  try {
    const { data, ok } = await api.get(
      `/bank/schoolingDetails?${month ? `month=${month}` : ""}${
        month && year ? "&" : ""
      }${year ? `year=${year}` : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
