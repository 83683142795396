import {
  IAdminForm,
  IUserProfile,
} from '../types/interfaces/IUserProfile.interface';
import { api } from '../utils/axios';

export const getUserData = async (email: string) => {
  try {
    const { data, ok } = await api.get<IUserProfile>(
      `/user/GetEmployeeWithEmail/${email}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateProfileDate = async (
  userData: IAdminForm,
  martialStatus: string,
  relation: string,
  dateOfBirth: string,
  confirmationDate: string,
  endOfServiceDate: string,
  id: string
) => {
  try {
    const { data, ok } = await api.put<IUserProfile>(
      `/user/UpdateAdmin/${id}`,
      {
        fullName: userData.fullName,
        contactNumber: userData.contactNumber,
        residentialAddress: userData.residentialAddress,
        maritalStatus: martialStatus,
        dateOfBirth: dateOfBirth,
        emergencyContactName: userData.emergencyContactName,
        emergencyContactNumber: userData.emergencyContactNo,
        emergencyContactRelation: relation,
        confirmationDate: confirmationDate,
        endOfServiceDate: endOfServiceDate,
        password: '',
      }
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
