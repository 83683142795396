import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  fetchMedicalDetails,
  fetchFuelDetails,
  fetchSalaryDetails,
  fetchSchoolingDetails,
} from "../../services/detail.service";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import {
  formatAmount,
  formatDate,
  formatYear,
} from "../../utils/dateFromatter";
import { toast } from "react-toastify";

const DetailScreen = () => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedReport, setSelectedReport] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const dateRef: any = useRef();
  const filterMaxDate = moment()
    .month(new Date().getMonth() - 1)
    .toDate();

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    if (date) {
      const { month, year } = formatDate(date);
      setMonth(month);
      setYear(year);
    } else {
      setMonth("");
      setYear("");
    }
  };

  const currency = localStorage.getItem("currency");

  useEffect(() => {
    handleDateChange(selectedDate);
  }, [selectedDate]);

  const handleClear = () => {
    setSelectedDate(undefined);
    setMonth("");
    setYear("");
  };

  const exportToXLSX = (filename: string, data: any, sheetName: string) => {
    const workBook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet["!cols"] = [
      { wch: 10 },
      { wch: 20 },
      { wch: 25 },
      { wch: 30 },
      { wch: 15 },
      { wch: 10 },
    ];
    XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
    XLSX.writeFile(workBook, `${filename}.xlsx`);
  };

  const processRows = (data: any) => {
    return data.length > 0
      ? data.map((row: any, index: any) => {
          const {
            accountNumber,
            beneficiaryName,
            customerReferenceNumber,
            amount,
            bankCode,
          } = row;
          return {
            "Sr. No": index + 1,
            "Account Number": accountNumber,
            "Beneficiary Name": beneficiaryName,
            "Customer Reference Number": customerReferenceNumber,
            Amount: amount,
            "Bank Code": bankCode || "",
          };
        })
      : [
          {
            "Sr. No": "",
            "Account Number": "",
            "Beneficiary Name": "",
            "Customer Reference Number": "",
            Amount: "",
            "Bank Code": "",
          },
        ];
  };

  const downloadData = async (fetchFunction: any, type: string) => {
    if (!month || !year) {
      toast.error("Please provide both month and year.");
      return;
    }

    const res: any = await fetchFunction(month, year);
    if (res?.res) {
      const dataIBFT = res.res[`${type}DataIBFT`] || [];
      const dataIFT = res.res[`${type}DataIFT`] || [];

      const processedIBFT = processRows(dataIBFT);
      const processedIFT = processRows(dataIFT);

      exportToXLSX(
        `${type}-IBFT-${year}-${month}`,
        processedIBFT,
        `${type} Data IBFT`
      );
      exportToXLSX(
        `${type}-IFT-${year}-${month}`,
        processedIFT,
        `${type} Data IFT`
      );
    } else {
      toast.error("Something went wrong.");
    }
  };

  const handleMedicalDownload = async () => {
    // if (month === undefined || year === undefined) {
    //   toast.error("Please provide both month and year.");
    //   return;
    // }

    const res: any = await fetchMedicalDetails(month, year);
    if (res?.res) {
      const medicalDataIBFT = res.res.medicalDataIBFT || [];
      const medicalDataIFT = res.res.medicalDataIFT || [];

      const exportToXLSX = (filename: string, data: any, sheetName: string) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 30 },
          { wch: 25 },
          { wch: 25 },
          { wch: 30 },
          { wch: 15 },
          { wch: 10 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any) => {
        return data.length > 0
          ? data.map((row: any, index: any) => {
              const {
                accountNumber,
                beneficiaryName,
                customerReferenceNumber,
                amount,
                bankCode,
              } = row;
              return {
                "Account Number": accountNumber,
                "Beneficiary Name": beneficiaryName,
                "Customer Reference Number": customerReferenceNumber,
                Amount: currency + " " + formatAmount(amount),
                "Bank Code": bankCode || "",
              };
            })
          : [
              {
                "Sr. No": "",
                "Account Number": "",
                "Beneficiary Name": "",
                "Customer Reference Number": "",
                Amount: "",
                "Bank Code": "",
              },
            ];
      };

      const saveAsXlsx = () => {
        const fuelDataIBFTProcessed = processRows(medicalDataIBFT);
        const fuelDataIFTProcessed = processRows(medicalDataIFT);

        exportToXLSX(
          `MedicalData-IBFT-${year}-${month}`,
          fuelDataIBFTProcessed,
          "Medical Data IBFT"
        );
        exportToXLSX(
          `MedicalData-IFT-${year}-${month}`,
          fuelDataIFTProcessed,
          "Medical Data IFT"
        );
      };

      saveAsXlsx();
    } else {
      // toast.error("Please provide both month and year.");
      return;
    }
  };

  const handleSalaryDownload = async () => {
    // if (month === undefined || year === undefined) {
    //   toast.error("Please provide both month and year.");
    //   return;
    // }

    const res: any = await fetchSalaryDetails(month, year);
    if (res?.res) {
      const SalaryDataIBFT = res.res.salaryDataIBFT || [];
      const SalaryDataIFT = res.res.salaryDataIFT || [];

      const exportToXLSX = (filename: string, data: any, sheetName: string) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 30 },
          { wch: 25 },
          { wch: 25 },
          { wch: 20 },
          { wch: 15 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any, isIBFT: boolean) => {
        if (isIBFT) {
          return data.length > 0
            ? data.map((row: any, index: any) => {
                const {
                  accountNumber,
                  beneficiaryName,
                  customerReferenceNumber,
                  amount,
                  bankCode,
                } = row;
                return {
                  "Account Number": accountNumber,
                  "Beneficiary Name": beneficiaryName,
                  "Customer Reference Number": customerReferenceNumber,
                  Amount: currency + " " + formatAmount(amount),
                  "Bank Code": bankCode || "",
                };
              })
            : [
                {
                  "Account Number": "",
                  "Beneficiary Name": "",
                  "Customer Reference Number": "",
                  Amount: "",
                  "Bank Code": "",
                },
              ];
        } else {
          return data.length > 0
            ? data.map((row: any, index: any) => {
                const {
                  accountNumber,
                  beneficiaryName,
                  customerReferenceNumber,
                  amount,
                  bankCode,
                } = row;
                return {
                  "Account Number": accountNumber,
                  "Beneficiary Name": beneficiaryName,
                  "Customer Reference Number": customerReferenceNumber,
                  Amount: currency + " " + formatAmount(amount),
                };
              })
            : [
                {
                  "Account Number": "",
                  "Beneficiary Name": "",
                  "Customer Reference Number": "",
                  Amount: "",
                },
              ];
        }
      };

      const saveAsXlsx = () => {
        const fuelDataIBFTProcessed = processRows(SalaryDataIBFT, true);
        const fuelDataIFTProcessed = processRows(SalaryDataIFT, false);

        exportToXLSX(
          `salaryData-IBFT-${year}-${month}`,
          fuelDataIBFTProcessed,
          "Salary Data IBFT"
        );
        exportToXLSX(
          `salaryData-IFT-${year}-${month}`,
          fuelDataIFTProcessed,
          "Salary Data IFT"
        );
      };

      saveAsXlsx();
    } else {
      // toast.error("Please provide both month and year.");
      return;
    }
  };

  const handleFuelDownload = async () => {
    // if (month === undefined || year === undefined) {
    //   toast.error("Please provide both month and year.");
    //   return;
    // }

    const res: any = await fetchFuelDetails(month, year);
    if (res?.res) {
      const fuelDataIBFT = res.res.fuelDataIBFT || [];
      const fuelDataIFT = res.res.fuelDataIFT || [];

      const exportToXLSX = (filename: string, data: any, sheetName: string) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 30 },
          { wch: 25 },
          { wch: 25 },
          { wch: 20 },
          { wch: 15 },
          { wch: 10 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any, isIBFT: boolean) => {
        if (isIBFT) {
          return data.length > 0
            ? data.map((row: any, index: any) => {
                const {
                  accountNumber,
                  beneficiaryName,
                  customerReferenceNumber,
                  amount,
                  bankCode,
                } = row;
                return {
                  "Sr. No": index + 1,
                  "Account Number": accountNumber,
                  "Beneficiary Name": beneficiaryName,
                  "Customer Reference Number": customerReferenceNumber,
                  Amount: currency + " " + formatAmount(amount),
                  "Bank Code": bankCode || "",
                };
              })
            : [
                {
                  "Sr. No": "",
                  "Account Number": "",
                  "Beneficiary Name": "",
                  "Customer Reference Number": "",
                  Amount: "",
                  "Bank Code": "",
                },
              ];
        } else {
          return data.length > 0
            ? data.map((row: any, index: any) => {
                const {
                  accountNumber,
                  beneficiaryName,
                  customerReferenceNumber,
                  amount,
                  bankCode,
                } = row;
                return {
                  "Account Number": accountNumber,
                  "Beneficiary Name": beneficiaryName,
                  "Customer Reference Number": customerReferenceNumber,
                  Amount: currency + " " + formatAmount(amount),
                };
              })
            : [
                {
                  "Account Number": "",
                  "Beneficiary Name": "",
                  "Customer Reference Number": "",
                  Amount: "",
                },
              ];
        }
      };

      const saveAsXlsx = () => {
        const fuelDataIBFTProcessed = processRows(fuelDataIBFT, true);
        const fuelDataIFTProcessed = processRows(fuelDataIFT, false);

        exportToXLSX(
          `FuelData-IBFT-${year}-${month}`,
          fuelDataIBFTProcessed,
          "Fuel Data IBFT"
        );
        exportToXLSX(
          `FuelData-IFT-${year}-${month}`,
          fuelDataIFTProcessed,
          "Fuel Data IFT"
        );
      };

      saveAsXlsx();
    } else {
      // toast.error("Please provide both month and year.");
      return;
    }
  };
  const handleSchoolingDownload = async () => {
    // if (month === undefined || year === undefined) {
    //   toast.error("Please provide both month and year.");
    //   return;
    // }

    const res: any = await fetchSchoolingDetails(month, year);
    if (res?.res) {
      const schoolingDataIBFT = res.res.schoolingDataIBFT || [];
      const schoolingDataIFT = res.res.schoolingDataIFT || [];

      const exportToXLSX = (filename: string, data: any, sheetName: string) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 10 },
          { wch: 35 },
          { wch: 25 },
          { wch: 30 },
          { wch: 15 },
          { wch: 10 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any) => {
        return data.length > 0
          ? data.map((row: any, index: any) => {
              const {
                accountNumber,
                beneficiaryName,
                customerReferenceNumber,
                amount,
                bankCode,
              } = row;
              return {
                "Sr. No": index + 1,
                "Account Number": accountNumber,
                "Beneficiary Name": beneficiaryName,
                "Customer Reference Number": customerReferenceNumber,
                Amount: currency + " " + formatAmount(amount),
                "Bank Code": bankCode || "",
              };
            })
          : [
              {
                "Sr. No": "",
                "Account Number": "",
                "Beneficiary Name": "",
                "Customer Reference Number": "",
                Amount: "",
                "Bank Code": "",
              },
            ];
      };

      const saveAsXlsx = () => {
        const fuelDataIBFTProcessed = processRows(schoolingDataIBFT);
        const fuelDataIFTProcessed = processRows(schoolingDataIFT);

        exportToXLSX(
          `SchoolingData-IBFT-${year}-${month}`,
          fuelDataIBFTProcessed,
          "Schooling Data IBFT"
        );
        exportToXLSX(
          `SchoolingData-IFT-${year}-${month}`,
          fuelDataIFTProcessed,
          "Schooling Data IFT"
        );
      };

      saveAsXlsx();
    } else {
      // toast.error("Please provide both month and year.");
      return;
    }
  };

  const detailsData = [
    {
      name: "Medical Allowance Details",
      handleDownload: handleMedicalDownload,
    },
    { name: "Fuel Allowance Details", handleDownload: handleFuelDownload },
    { name: "Salary Details", handleDownload: handleSalaryDownload },
    {
      name: "School Allowance Details",
      handleDownload: handleSchoolingDownload,
    },
  ];

  const handleKeyDown = (e: any) => {
    e.preventDefault(); // Prevent all key presses
  };

  return (
    <>
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
      <div className="basis-full px-[15px] xl:px-[30px]">
        <EmployeeStatus
          heading="Details"
          leadText=""
          StatusLogo={
            <ArrowTopRightOnSquareIcon className="w-9 h-9 text-[#33475F]" />
          }
          Subscription=""
          showFirst={""}
          showSecond={false}
          Request={``}
          PendingDate={""}
        />
        <div className="basis-2/3">
          <div className="relative flex md:flex-row flex-col md:items-center md:justify-between mb-3 md:mb-6 gap-3">
            <div className="relative mt-3">
              <ReactDatePicker
                ref={dateRef}
                className="cursor-pointer rounded-lg md:min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                dateFormat="MMMM yyyy"
                placeholderText="Select Month"
                showMonthYearPicker
                selected={selectedDate}
                onChange={(date) => handleDateChange(date as Date)}
                preventOpenOnFocus
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {selectedDate ? (
                <button
                  className="absolute  right-3 mt-3"
                  onClick={handleClear}
                >
                  <XMarkIcon className="text-black w-5 h-5" />
                </button>
              ) : (
                <span
                  className="absolute  right-3 cursor-pointer mt-3"
                  onClick={() => dateRef.current.setOpen(true)}
                >
                  <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="basis-full md:basis-2/3">
          <div className="flex flex-col md:flex-column mt-5 gap-3 xl:gap-5">
            <div className="basis-full md:basis-2/3 relative">
              <table className="min-w-full ">
                <thead>
                  <tr>
                    <th className="py-2">Detail Type</th>
                    <th className="py-2">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {detailsData.map((report, index) => (
                    <tr key={index}>
                      <td className="py-2">{report.name}</td>
                      <td className="py-2">
                        <button
                          className="bg-[#33475F] text-white rounded px-2 py-1 font-medium"
                          onClick={() => report.handleDownload()}
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default DetailScreen;
