import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import TotalEmployees from "../../components/totalEmployees/TotalEmployees";
import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import PerformanceForm from "../../components/Form/PerformanceForm";
import {
  IEvaluationTemplate,
  IPerformanceData,
  IPerformanceForm,
  ITotalEmployee,
  Point,
} from "../../types/interfaces/IPerformance.interface";
import {
  SaveEmpEvaData,
  fetchEmpEvalForm,
  fetchEmpEvalTemplate,
  fetchEmpsForPerformance,
  fetchEvaByYear,
  fetchTotalEmpData,
  delteData$,
  saveData$,
  refreshData$,
  updateData$,
  UpdateEmpEvaData,
} from "../../services/performance.service";
import EmployeeList from "../../components/performance/EmployeeList";
import { IEvalByYear } from "../../types/interfaces/IEvalByYear";
import { useLocation } from "react-router-dom";
import moment from "moment";

const PerformanceMangement = (props: any) => {
  
  function formatDate(date: Date): string {
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: '2-digit' });
    const [{ value: month }, , { value: year }] = formatter.formatToParts(date);
    return `${month} ${year}`;
  }


  function createTotalEmployee(
    date: string = formatDate(new Date()),
    totalActiveEmployee: number = 0,
    totalEmployeeRated: number = 0
  ): ITotalEmployee {
    return {
      date,
      totalActiveEmployee,
      totalEmployeeRated,
    };
  }


  

  const [performanceData, setPerformanceData] = useState<IPerformanceData[]>([]);
  const [evalData, setEvalData] = useState<any>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<IPerformanceData>({} as IPerformanceData);
  const [evalFormData, setEvalFormData] = useState<IPerformanceForm>();
  const [evalFormDataCopy, setEvalFormDataCopy] = useState<IPerformanceForm>();
  const [evalTemplateData, setEvalTemplateFormData] = useState<IEvaluationTemplate>();
  const [evalTemplateDataCopy, setEvalTemplateFormDataCopy] = useState<IEvaluationTemplate>();
  const [totalEmpData, setTotalEmpData] = useState<ITotalEmployee>(createTotalEmployee());
  const [userCount, setUserCount] = useState<number>(0);
  const [evaluatedUserCount, setEvaluatedUserCount] = useState(0);
  const [userStatus, setUserStatus] = useState<boolean>(false);
  const [evalYearData, setEvalYearData] = useState<IEvalByYear>();
  const [year, setYear] = useState<number>();
  const [updateEval, setUpdateEval] = useState<any>('');
  const [saveResults, setSaveResults] = useState<any>("");
  const [history, setHistory] = useState<boolean>(false);
  const [inEditForm,setInEditForm] = useState(false)
  let location = useLocation();
  const edit = location.state && location.state.edit;

  const saveResult = async (evalData: any, userId: string) => {
    const res = await SaveEmpEvaData(evalData, userId);
    if (res?.res) {
      setSaveResults(res.res);
      setInEditForm(true)
      getEmpEvalForm()
    }
  };

  const getPerformanceData = async () => {
    const res: any = await fetchEmpsForPerformance();
    if (res?.res) {
      const find = res.res.find((x: any) => x.userId === selectedEmployeeId.userId);
      if (find) {
        setSelectedEmployeeId(find);
        setInEditForm(find.isEvaluated? true:false)
      }
      setPerformanceData(res.res as IPerformanceData[]);
    }
  };

  const getEmpEvalForm = async () => {
    if(selectedEmployeeId.userId){
    const res = await fetchEmpEvalForm(selectedEmployeeId.userId);
    setEvalFormData([] as any);
    setEvalFormDataCopy([] as any);
    if (res?.res) {
      setEvalFormData(res.res as IPerformanceForm);
      setEvalFormDataCopy(res.res as IPerformanceForm);
    }}
  };

  const getEmpEvalTemplate = async () => {
    const res = await fetchEmpEvalTemplate();
    setEvalFormData([] as any);
    setEvalFormDataCopy([] as any);
    if (res?.res) {
      setEvalTemplateFormData(res.res as IEvaluationTemplate);
      setEvalTemplateFormDataCopy(res.res as IEvaluationTemplate);
    }
  };

  const updateResult = async (id: string, UserId: string, evalData: any) => {
    const res = await UpdateEmpEvaData(id || "", UserId || "", evalData);
    if (res?.res) {
      setUpdateEval(res.res);
      await getEvalByYearUserData();
      await getEmpEvalForm();
      setHistory(true);
      setInEditForm(selectedEmployeeId?.isEvaluated??false)
    }
  };

  const getTotalEmp = async () => {
    const res = await fetchTotalEmpData();
    setEvalFormData([] as any);
    if (res?.res) {
      setTotalEmpData(res.res as ITotalEmployee);
    }
  };

  useEffect(() => {
    if (performanceData) {
      setUserCount(performanceData.length);
      const evaluatedUserCount = performanceData.reduce(
        (count, user) => count + (user.isEvaluated ? 1 : 0),
        0
      );
      setEvaluatedUserCount(evaluatedUserCount);
    }
  }, [performanceData]);

  const getEvalByYearUser = async () => {
    if(selectedEmployeeId.userId){
    const res: any = await fetchEvaByYear(selectedEmployeeId.userId);

    if (res?.res) {
      setEvalYearData(res.res as IEvalByYear);
    } else {
      setEvalYearData({} as IEvalByYear);
      setEvalFormData({} as IPerformanceForm);
      setEvalFormDataCopy({} as IPerformanceForm);
    }
  }
  };

  const getEvalByYearUserData = async () => {
    if(selectedEmployeeId.userId){
    const res: any = await fetchEvaByYear(selectedEmployeeId.userId, year);

    if (res?.res) {
      setEvalYearData(res.res as IEvalByYear);
    } else {
      setEvalYearData({} as IEvalByYear);
    }
  }
  };


  useEffect(() => {
    getTotalEmp();
    getEmpEvalTemplate();
    getEmpEvalForm();
    getPerformanceData();
    getEvalByYearUserData();
  }, [saveResults]);

  useEffect(() => {
    if (!selectedEmployeeId) {
      setEvalFormData([] as any);
    }
    getEvalByYearUser();
    setYear(moment().year());
    getEmpEvalForm();
    setInEditForm(selectedEmployeeId?.isEvaluated ? true:false)

  }, [selectedEmployeeId.userId, edit]);

  useEffect(() => {
    getEvalByYearUserData();
  }, [year]);

  const handleSetHistory = (flag: boolean, data?: any) => {
    setHistory(flag);
    if (data) {
      setInEditForm(data?.ID ? true:false)
      setEvalFormData(data as IPerformanceForm);
      setEvalFormDataCopy(data as IPerformanceForm);
    }
  };

  const handleUserStatus = (flag: boolean) => {
    setUserStatus(flag);
  };

  const discard = () => {
    setEvalFormData(JSON.parse(JSON.stringify(evalFormDataCopy)));
  };


  return (
    <>
      {/* <Header /> */}
      {/* <div className="flex flex-row"> */}
        {/* <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
  </div> */}
   
   <div className="basis-full px-[15px] xl:px-[30px]"> 
          <EmployeeStatus
            heading="Performance"
            leadText="Rate your employees monthly performance"
            StatusLogo={
              <PresentationChartBarIcon className="w-9 h-9 text-[#33475F]" />
            }
            Subscription="Employees Rated"
            showFirst={true}
            showSecond={false}
            Request={`${totalEmpData?.totalEmployeeRated} / ${totalEmpData?.totalActiveEmployee}`}
            PendingDate={totalEmpData?.date}
          />
          <div className="basis-full md:basis-2/3">
            <div className="flex flex-col md:flex-row mt-5 gap-3 xl:gap-5">
              <div className="basis-full md:basis-1/3">
                <EmployeeList
                  performanceData={performanceData}
                  selectedEmployeeId={selectedEmployeeId}
                  setSelectedEmployeeId={setSelectedEmployeeId}
                />
              </div>
              <div className="basis-full md:basis-2/3">
                <PerformanceForm
                  history={history}
                  setHistory={handleSetHistory}
                  evalTemplateData={evalTemplateData}
                  evalFormData={evalFormData}
                  selectedEmployeeId={selectedEmployeeId}
                  evalYearData={evalYearData}
                  setYear={setYear}
                  year={year}
                  edit={edit}
                  performanceData={performanceData}
                  discardBtn={discard}
                  update={updateResult}
                  saveResult= {saveResult}
                  getPerformanceData={getPerformanceData}
                  getEvalByYearUser={getEvalByYearUserData}
                  getEmpEvalForm={getEmpEvalForm} 
                  inEditForm={inEditForm}
                  getTotalEmp={getTotalEmp}
                />
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default PerformanceMangement;
