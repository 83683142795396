import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import TablePagination from "../pagination/TablePagination";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import {
  AppliedEncashment,
  IEncashment,
} from "../../types/interfaces/IEncashment.interface";
import {
  formatDateSlash,
  formatDateSpace,
  formatAmount,
} from "../../utils/dateFromatter";
import moment from "moment";
import AssetsPopOver from "../../widgets/AssetsPopOver";
import { Salary } from "../../types/interfaces/ISalaryRecords.interface";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import {
  updateEncashmentPaymentStatus,
  updateEncashmentStatus,
} from "../../services/leaveEncashment.service";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface Props {
  encashmentRequests: IEncashment;
  selectedRecord: AppliedEncashment;
  setSelectedRecord: (req: AppliedEncashment) => void;
  getEncashmentRequests: (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => void;
  openPopoverAssetId: string | null;
  setOpenPopoverAssetId: (id: string | null) => void;
}

const EmployeeSettlementTable: React.FC<Props> = ({
  encashmentRequests,
  selectedRecord,
  setSelectedRecord,
  getEncashmentRequests,
  openPopoverAssetId,
  setOpenPopoverAssetId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];
  const [reqAgain, setReqAgain] = useState(false);

  const [actionToPerform, setActionToPerform] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState("");

  let timeoutId: NodeJS.Timeout | null = null;

  const handleComments = (comments: string) => {
    setComments(comments);
  };

  const [showPayModal, setShowPayModal] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<any>("");

  const [cuurentPayId, setCurrentPayId] = useState<string>("");

  const ref: any = useRef();
  useOnClickOutside(ref, () => setShowPayModal(false));

  const handlePaymentModel = (id: string) => {
    setShowPayModal(true);
    setCurrentPayId(id);
  };

  const handlePayment = async () => {
    const resp = await updateEncashmentPaymentStatus(cuurentPayId);
    if (resp) {
      setPaymentStatus(resp);
      setShowPayModal(false);
      toast.success("Payment Done Sucessfully");
    }
  };
  // const toggleAssetsList = (record: AppliedEncashment) => {
  //   if (openPopoverAssetId === record.) {
  //     setOpenPopoverAssetId(null);
  //   } else {
  //     setOpenPopoverAssetId(record.ID);
  //   }
  //   setSelectedRecord(record);
  //   // setIsListOpen(!isListOpen);
  //   // !isListOpen && isPopoverOpen && setIsPopoverOpen(false);
  // };
  const handleToggle = (record: AppliedEncashment) => {
    setSelectedRecord(record);
    if (openPopoverAssetId === record.userID) {
      setOpenPopoverAssetId(null);
    } else {
      setOpenPopoverAssetId(record.userID);
    }
  };
  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getEncashmentRequests(
            formatDateSlash(startDate),
            formatDateSlash(endDate),
            searchQuery
          );
        } else getEncashmentRequests(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getEncashmentRequests(
        formatDateSlash(startDate),
        formatDateSlash(endDate)
      );
    } else {
      getEncashmentRequests();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery, paymentStatus]);

  const openModal = (event: any, record: AppliedEncashment) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    console.log(record);
    console.log(actionToPerform);
    setSelectedRecord(record);
    console.log("Helllo");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const handleEnchashmentStatus = async () => {
    console.log(selectedRecord);
    const reqBody = {
      comment: comments,
    };
    const res = await updateEncashmentStatus(
      actionToPerform,
      selectedRecord?.encashmentId,
      reqBody
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      getEncashmentRequests();
      if (actionToPerform === "rejected") {
        toast.success("Encashment Request Rejected Successfully");
      } else {
        toast.success("Encashment Request Approved Successfully");
      }
      closeModal();
    }
  };
  const currency = localStorage.getItem("currency");
  const handleKeyDown = (e:any ) => {
    e.preventDefault(); // Prevent all key presses
  };
  

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center  mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center z-10 gap-2">
            <ReactDatePicker
              className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              onChange={(date) => {
                setDateRange(date as Date[]);
              }}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select Range"
              selectsRange={true}
              dateFormat="dd/MM/yyyy"
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}

            />
            {startDate ? (
              <button
                className="absolute top-3 right-4 items-center"
                onClick={handleClear}
              >
                <XMarkIcon className="text-black w-5 h-5" />
              </button>
            ) : (
              <span className="absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        {encashmentRequests?.appliedEncashments?.length > 0 ? (
          <>
            <div className=" Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)] ">
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    {/* <th>Assets Acquired</th> */}
                    {/* <th>
                      Pending Bonus <br /> Encashment
                    </th> */}
                    {/* <th>
                      Pending Salary <br /> Encashment
                    </th> */}
                    <th>
                      Pending Dues
                    </th>
                    <th>Date Applied</th>
                    <th>Payment Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {encashmentRequests?.appliedEncashments?.map((req, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.userName}</td>
                      {/* <td data-cell="Assets Acquired" className="relative">
                        <button
                          className="text-sm text-[#378EB5] font-semibold"
                          onClick={() => handleToggle(req)}
                        >
                          Assets
                        </button>
                        {openPopoverAssetId === req?.ID && (
                          <AssetsPopOver
                            record={selectedRecord?.Assets as string[]}
                          />
                        )}
                      </td> */}
                      {/* <td data-cell="Pending Bonus Encashment">
                        {req?.PendingBonusEncashment}
                      </td>
                      <td data-cell=" Pending Salary Encashment">
                        {req?.PendingSalaryEncashment}
                      </td> */}
                      <td data-cell="Pending Leaves Encashment">
                        {currency} {"  "}
                        {formatAmount(req?.pendingLeavesEncashment)}
                      </td>

                      <td data-cell="Date Applied">
                        <div className="flex justify-start">
                          {formatDateSpace(
                            moment(req?.createdAt).format("DD/MM/YYYY")
                          )}
                        </div>
                      </td>

                      <td>{req?.paymentStatus}</td>
                      <td>
                        <div className="flex items-center justify-end gap-1">
                          {req?.paymentStatus === "Unpaid" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="paid"
                                onClick={() =>
                                  handlePaymentModel(req?.encashmentId)
                                }
                              >
                                Pay
                              </button>
                            </>
                          ) : (
                            ""
                          )}

                          {req?.status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="accepted"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.status === "accepted" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <TablePagination /> */}
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Settlement Request Available.
            </p>
          </div>
        )}
      </div>
      <ApproveRejectModal
        isOpen={isModalOpen}
        onClose={closeModal}
        comments={comments}
        handleComments={handleComments}
        recordData={selectedRecord}
        actionToPerform={actionToPerform}
        sendRequest={handleEnchashmentStatus}
      />

      {showPayModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={ref} className="bg-white rounded-lg p-5 w-[365px]">
            <h2 className="text-lg font-semibold mb-4">Confirm Payment </h2>
            <p className="mb-4">Are you sure you want to pay?</p>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setShowPayModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#378EB5] text-white rounded px-4 py-2"
                onClick={handlePayment}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeSettlementTable;
