import React from "react";
import {
  ChatBubbleLeftEllipsisIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import { ITotalSuggestion } from "../../types/interfaces/ITotalSuggestion";

interface Props {
  suggestionCount : number
  approveCount: number;

}

const SuggestionStatus: React.FC<Props> = ({suggestionCount, approveCount }) => {
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center px-3 xl:px-5 py-3 sm:py-5 lg:py-8 bg-white rounded-2xl gap-3">
      <div className="flex items-center gap-3">
        <div className="bg-[#F0F7FB] p-2 rounded-xl">
          <ChatBubbleLeftEllipsisIcon className="w-9 h-9 text-[#33475F]" />
        </div>
        <div>
          <h3 className="text-xl sm:text-2xl font-semibold text-[#33475F]">
            Suggestion
          </h3>
          <p className="text-sm text-[#33475F]">
            Manage All Suggestions of the employees
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
        <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-[8px] xl:px-4 w-72.5">
          <p className="text-sm font-semibold text-[#5C6C7F] ">
            Total Suggestions Received
          </p>
          <h3 className="text-base font-semibold text-[#33475F]">
            {suggestionCount ?? 0}
          </h3>
        </div>

        <div className="bg-[#EBEDEF] rounded-2xl py-[10px] px-2 lg:px-4 w-64">
          <p className="text-sm font-semibold text-[#5C6C7F]">
            Total Suggestions Approved
          </p>
          <h3 className="text-base font-semibold text-[#33475F]">
            {approveCount ?? 0}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SuggestionStatus;
