// Path: components/BonusTable.tsx

import React, { useEffect, useRef, useState } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { Salary } from "../../types/interfaces/ISalaryRecords.interface";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { GrantBonus, allBonuses } from "../../types/interfaces/IBonus";
import BonusForm from "../Modals/BonusModel";
import { grantBonus } from "../../services/bonus.service";
import { setYear, getYear, addYears } from "date-fns";
import { formatAmount } from "../../utils/dateFromatter";

interface Props {
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  advSalaryRequests: allBonuses | any;
  setAdvSalaryRequests: Function;
  getAllAdvSalary: Function;
}

const BonusTable: React.FC<Props> = ({
  openPopoverId,
  setOpenPopoverId,
  advSalaryRequests,
  getAllAdvSalary,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBonusFormOpen, setIsBonusFormOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Salary>();
  const [selectedYears, setSelectedYears] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [paymentStatus, setPaymentStatus] = useState<any>("");
  const [currentPayId, setCurrentPayId] = useState<string>("");
  const [scrollOnDate, setScrollOnDate] = useState(false);
  const [yearParam, setYearParam] = useState<Date | null>(null);
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const ref: any = useRef();
  const datePickerRef = useRef<ReactDatePicker>(null);
  useOnClickOutside(ref, () => setIsModalOpen(false));

  useEffect(() => {
    if (!selectedYears) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [selectedYears]);

  useEffect(() => {
    getAllAdvSalary(yearParam?.getFullYear());
  }, [reqAgain, paymentStatus, yearParam]);

  const openModal = (event: any, record: Salary) => {
    setIsModalOpen(true);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const handleClear = () => {
    setSelectedYears([null, null]);
    setYearParam(null);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTopRef.current?.scrollTo(0, 0);
  }, [searchQuery, scrollOnDate]);

  const handleBonusSubmit = async (bonus: GrantBonus) => {
    const response: any = await grantBonus(bonus);
    if (response.res) {
      toast.success("Bonus data submitted");
      setIsBonusFormOpen(false);
      setSelectedYears([null, null]);
      setYearParam(null);
      setReqAgain(!reqAgain);
    }
  };

  const CustomYearRangePicker = ({
    value,
    onClick,
  }: {
    value?: string;
    onClick?: () => void;
  }) => (
    <button
      className={`mt-0 block w-full p-4 ${
        value ? "" : "-ml-[35px] text-sm py-3 px-5 font-semibold"
      }`}
      onClick={onClick}
    >
      {value ? value : "Select Range"}
    </button>
  );

  const filteredBonuses = advSalaryRequests?.allBonuses?.filter((bonus: any) =>
    bonus.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleYearRangeChange = (date: [Date | null, Date | null]) => {
    if (date[0]) {
      const startYear = setYear(date[0], getYear(date[0]));
      setYearParam(startYear);
      const endYear = setYear(addYears(startYear, 1), getYear(startYear) + 1);
      setSelectedYears([startYear, endYear]);
      datePickerRef.current?.setOpen(false);
    } else {
      setSelectedYears([null, null]);
      setYearParam(null);
    }
  };
  const currency = localStorage.getItem("currency");

  return (
    <>
      <div className="py-7">
        
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3 ">
              <input
                className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />
              <span className="absolute top-3 left-3">
                <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
              </span>
            <div className="cursor-pointer bg-[#ffffff] h-[45px] border border-gray-300 rounded-md relative flex items-center gap-1 sm:gap-2 z-10 w-[220px]">
              <div className="w-full h-full flex items-center">
                <ReactDatePicker
                  ref={datePickerRef}
                  id="datePicker"
                  selectsRange={true}
                  onChange={(date) => handleYearRangeChange(date as any)}
                  selectsStart
                  startDate={selectedYears[0]}
                  endDate={selectedYears[1]}
                  showYearPicker
                  dateFormat={["yyyy", "yyyy"]}
                  minDate={setYear(new Date(), 2020)}
                  showFourColumnMonthYearPicker
                  maxDate={new Date()}
                  customInput={
                    <CustomYearRangePicker
                      value={
                        selectedYears[0] && selectedYears[1]
                          ? `${getYear(selectedYears[0]!)}-${getYear(
                              selectedYears[1]!
                            )}`
                          : "Select Year"
                      }
                    />
                  }
                  className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                />
                {selectedYears[0] || selectedYears[1] ? (
                  <button
                    className="absolute top-3 right-4"
                    onClick={handleClear}
                  >
                    <XMarkIcon className="text-black w-5 h-5" />
                  </button>
                ) : (
                  <span
                    className="absolute top-3 right-4"
                    onClick={() => datePickerRef.current?.setOpen(true)}
                  >
                    <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                  </span>
                )}
              </div>
            </div>
            <button

            className="ExportBtn text-sm font-semibold text-white bg-[#378EB5] flex items-center ml-auto gap-1 bg-[rgb(55,142,181)] text-white  py-2.5 px-3 rounded-lg max-w-[135px]"
            onClick={() => setIsBonusFormOpen(true)}
            >
              Add Bonus
            </button>
        </div>

        {/* <div className="flex justify-end">
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg max-w-[135px]"
            onClick={() => setIsBonusFormOpen(true)}
          >
            Add Bonus
          </button>
        </div> */}
        {filteredBonuses?.length > 0 ? (
          <>
            <div
              className="Employees my-4 md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-390px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Bonus Amount</th>
                    <th>Bonus Purpose</th>
                    <th>Month</th>
                    <th>Year</th>
                    <th>Awarded At</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBonuses.map((req: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.name}</td>
                      <td data-cell="Amount">
                        {currency} {"  "}
                        {formatAmount(req?.amount)}
                      </td>
                      <td data-cell="Reason">
                        <div className="relative">
                          <button>{req?.purpose}</button>
                        </div>
                      </td>
                      <td data-cell="Month">{req?.month}</td>
                      <td data-cell="Year">{req?.year}</td>
                      <td data-cell="Date Applied">{req?.awardedAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Bonus Request Available.
            </p>
          </div>
        )}
      </div>

      {isBonusFormOpen && (
        <BonusForm
          onClose={() => setIsBonusFormOpen(false)}
          onSubmit={handleBonusSubmit}
        />
      )}
    </>
  );
};

export default BonusTable;
