import { ICreateNotice } from "../types/interfaces/INoticeBoard.interface";
import { api } from "../utils/axios";

export const fetchEmpDashboardInfo = async () => {
  try {
    const { data, ok } = await api.get(`/Dashboard/details`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const createNotice = async (reqBody: ICreateNotice) => {
  try {
    const { data, ok } = await api.post(`/noticeBoard/createNotice`, reqBody);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchAllNotices = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/noticeBoard/getAllNotices?${search ? "search=" + search : ""}${
        startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateNotice = async (
  reqBody: ICreateNotice,
  noticeID: string
) => {
  try {
    const { data, ok } = await api.put(
      `/noticeBoard/updateNotice/${noticeID}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const removeNotice = async (noticeID: string) => {
  try {
    const { data, ok } = await api.delete(
      `/noticeBoard/removeNotice/${noticeID}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
