import React, { useState } from "react";
import StarRatings from "react-star-ratings";

interface IProps {
  ratingValue: any;
}
const StartRating = (props: IProps) => {
  return (
    <StarRatings
      rating={+props.ratingValue}
      starRatedColor="#378EB5"
      starSpacing="2px"
      starDimension="16px"
      //   changeRating={changeRating}
      numberOfStars={5}
      name="rating"
    />
  );
};

export default StartRating;
