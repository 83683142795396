import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { CalculatorIcon, UserMinusIcon } from "@heroicons/react/24/solid";
import EmployeeSettlementTable from "../../components/Table/EmployeeSettlementTable";
import { fetchAllEncashments } from "../../services/leaveEncashment.service";
import {
  AppliedEncashment,
  IEncashment,
} from "../../types/interfaces/IEncashment.interface";

const EmployeeSettlement = () => {
  const [encashmentRequests, setEncashmentRequests] = useState<IEncashment>({
    appliedEncashments: [],
  });
  const [selectedRecord, setSelectedRecord] = useState<AppliedEncashment>();
  const [numOfEncashmentReq, setNumOfEncashmentReq] = useState<number>(0);
  const [openPopoverAssetId, setOpenPopoverAssetId] = useState<string | null>(
    null
  );

  const getEncashmentRequests = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllEncashments(startDate, endDate, searchQuery);
    if (res?.res) {
      setEncashmentRequests(res?.res as IEncashment);
    }
  };

  useEffect(() => {
    if (encashmentRequests?.appliedEncashments?.length < 1) return;
    setNumOfEncashmentReq(encashmentRequests?.appliedEncashments?.length);
  }, [encashmentRequests]);

  return (
    <div className="basis-full px-[15px] xl:px-[30px]"
      onClick={() => {
        openPopoverAssetId &&
          openPopoverAssetId?.length > 0 &&
          setOpenPopoverAssetId(null);
      }}
    >
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div >
          <EmployeeStatus
            heading="Leave Encashment"
            leadText="Manage remaining leaves and their payments"
            StatusLogo={<CalculatorIcon className="w-9 h-9 text-[#33475F]" />}
            Subscription="Employees Applied"
            price={numOfEncashmentReq}
            showFirst={true}
            showSecond={false}
          />
          <div className="basis-2/3">
            <EmployeeSettlementTable
              encashmentRequests={encashmentRequests}
              selectedRecord={selectedRecord as AppliedEncashment}
              setSelectedRecord={setSelectedRecord}
              getEncashmentRequests={getEncashmentRequests}
              openPopoverAssetId={openPopoverAssetId}
              setOpenPopoverAssetId={setOpenPopoverAssetId}
            />
          </div>
        </div>
      </div>
    // </div>
  );
};

export default EmployeeSettlement;
