import React, { useRef, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Props {
  attachments: string[];
  currentSlide: number;
  setCurrentSlide: (num: number) => void;
  sliderRef: React.RefObject<Slider>; // Accept sliderRef as a prop
}

const ViewSlider: React.FC<Props> = ({
  attachments = [],
  currentSlide,
  setCurrentSlide,
  sliderRef,
}) => {
  const settings: Settings = {
    customPaging: (i: number) => (
      <a>
        <img src={attachments[i]} alt={`Attachment picture ${i + 1}`} />
      </a>
    ),
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
  };

  return (
    <div className="slider text-center p-3 pb-11 h-full">
      {attachments.length > 0 ? (
        <Slider ref={sliderRef} {...settings}>
          {attachments.map((attachment, index) => (
            <div key={index} className="Attachments">
              <img
                src={attachment}
                alt={`Attachment picture ${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>No attachments to display</p>
      )}
      <p className="text-sm text-[#333333]">
        <span className="font-semibold">{currentSlide + 1} </span>/
        <span className="font-normal"> {attachments.length}</span>
      </p>
    </div>
  );
};

export default ViewSlider;
