import { api } from "../utils/axios";

export const fetchReportList = async () => {
  try {
    const { data, ok } = await api.get("/reportsAndCertificates/getReportList");
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchJoinerLeaver = async (year: number) => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getJoinersAndLeavers?${
        year ? `year=${year}` : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null };
  }
};
export const fetchUserWithEmail = async () => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getUsersDetailsOfPerk`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchEligibleUser = async () => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getUsersForExpCert`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchAppraisalHistory = async (
  userEmail?: string,
  year?: string
) => {
  console.log("email in svc: ", userEmail, year);
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getAppraisalHistory?${
        userEmail ? `userEmail=${userEmail}` : ""
      }${year ? `year=${year}` : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchEligibleUserForBank = async () => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getUsersForEmpCertBank`
    );
    console.log("objectbank", data);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchEligibleUserForJob = async () => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getUsersForEmpCertJob`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchEligibleUserForJInter = async () => {
  try {
    const { data, ok } = await api.get(
      `/reportsAndCertificates/getUsersForInternCert`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
