import { ApiErrorResponse, create } from "apisauce";
import { signOut, refreshToken, acquireAccessToken, } from "../services/auth.service";
import spinnerSvc from "../utils/spinner-service";
import { apiBaseUrl } from "../constants/environment.constants";
import { removeUser } from "../features/Auth Slice/authSlice";
import { sign } from "crypto";
import { storageKeys } from "../constants/storage.constants";
import { toast } from "react-toastify";
import {  protectedResources } from '../config/auth-config';


let isRefreshing = false;
let waitingQueue: any[] = [];

if (!apiBaseUrl) {
  throw new Error("No Api apiBaseUrl");
}

export const api = create({
  baseURL: apiBaseUrl,
  headers: { Accept: "application/json" },
});

const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
  waitingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  isRefreshing = false;
  waitingQueue = [];
};

api.axiosInstance.interceptors.request.use(
  async (config: any) => {

    const accessToken = await acquireAccessToken();    
    if (accessToken ) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

api.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    if (response.data === "Admin updated successfully") {
      toast.success(` ${response.data}`);
    }
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

  
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        toast.error(`Error: ${err.response.data.error}`);
        spinnerSvc.stop();

        return err;

        case 401:
          console.error(err);
          // Attempt silent refresh before signing out
          try {
            const newAccessToken = await acquireAccessToken();
            if (newAccessToken) {
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              return api.axiosInstance(originalRequest);  // Retry the request
            }
          } catch (tokenError) {
            // Silent token refresh failed, proceed with sign out
            signOut();
          }
          spinnerSvc.stop();
          return err;
        

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);

// import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

// import { apiBaseUrl } from "../constants/environmentConstants";

// import { refreshToken } from "../services/auth.service";

// import { logout } from "../services/auth.service";

// import spinnerSvc from "./spinner-service";

// if (!apiBaseUrl) {

//   throw new Error("No Api apiBaseUrl");

// }

// axios.defaults.headers.post["Content-Type"] = "application/json";

// const createApi = (path: string) => {

//   const api = axios.create({

//     baseURL: `${apiBaseUrl}${path}`,

//     timeout: 10000,

//     headers: {

//       "Content-Type": "application/json",

//     },

//   });

//   api.interceptors.request.use(

//     async (config: InternalAxiosRequestConfig) => {

//       const accessToken = localStorage.getItem("atToken") || "";

//       if (accessToken !== "") {

//         config.headers["Authorization"] = `Bearer ${accessToken}`;

//       }

//       spinnerSvc.start();

//       return config;

//     },

//     (error: AxiosError) => Promise.reject(error),

//   );

//   api.interceptors.response.use(

//     (response: any) => {

//       spinnerSvc.stop();

//       return response;

//     },

//     async (err) => {

//       const originalRequest = err.config;

//       spinnerSvc.stop();

//       if (err.response?.status === 401 && !originalRequest._retry) {

//         try {

//           const data = await refreshToken();

//           if (data?.accessToken) {

//             api.defaults.headers.common[

//               "Authorization"

//             ] = `Bearer ${data?.accessToken}`;

//             originalRequest._retry = true;

//             return api(originalRequest);

//           } else {

//             throw new Error("Token refresh failed");

//           }

//         } catch (refreshError) {

//           spinnerSvc.stop();

//         }

//       }

//     },

//   );

//   return api;

// };

// export default createApi;

// // function logoutAndNavigateToHome() {

// //   logout();

// //   const navigate = useNavigate();

// //   navigate("/login");

// // }
