import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import {
  CalendarDaysIcon,
  ClipboardDocumentListIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import EmpReportsItem from "../../components/EmpReports/EmpReportsItem";
import {
  fetchAppraisalHistory,
  fetchJoinerLeaver,
  fetchReportList,
} from "../../services/empReports.service";
import { IEmpReports } from "../../types/interfaces/IEmpRports";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { formatAmount, formatYear } from "../../utils/dateFromatter";
import { IJoinerLeaver } from "../../types/interfaces/ILeaver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Salary } from "../../types/interfaces/ISalaryRecords.interface";
import ReportTable from "../../components/EmpReports/ReportTable";

const EmpReports = () => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [reqAgain, setReqAgain] = useState(false);

  const filterMaxDate = moment()
    .month(new Date().getMonth() - 1)
    .toDate();

  const [reportsData, setReportsData] = useState<IEmpReports>({
    reportList: [],
  });
  const [selectedReport, setSelectedReport] = useState("Perk History");
  const [leaverData, setLeaverData] = useState<IJoinerLeaver>();

  const currency = localStorage.getItem("currency");


  const getReportsData = async () => {
    const res: any = await fetchReportList();
    if (res?.res) {
      setReportsData(res.res as IEmpReports);
    } else {
      setReportsData({ reportList: [] });
    }
  };
  console.log(selectedDate);
  const getJoinerAndLeaver = async () => {
    const res: any = await fetchJoinerLeaver(formatYear(selectedDate));
    if (res?.res) {
      setLeaverData(res.res as IJoinerLeaver);
      console.log("data", leaverData);
    } else {
      alert("Something wrong");
    }
  };
  const handleDownloadJoinerLeaverExcel = async () => {
    try {
      const year = formatYear(selectedDate);
      const res: any = await fetchJoinerLeaver(year);
      if (res?.res) {
        const joiners = res.res.joiners || [];
        const leavers = res.res.leavers || [];

        const processRows = (data: any) => {
          return data.map((row: any, index: any) => {
            const { userId, ...rest } = row;
          
            return { "Sr. No": index + 1, "Name"  : row.name , "Designation" : row.designation , "Joining Date" : row.joiningDate , "Salary" : currency + " " + formatAmount(row.salary) };
          });
        };

        const joinersProcessed = processRows(joiners);
        const leaversProcessed = processRows(leavers);

        const exportToXLSX = (
          filename: string,
          data: any,
          sheetName: string
        ) => {
          const workBook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data);
          worksheet["!cols"] = [
            { wch: 10 },
            { wch: 25 },
            { wch: 25 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
          ];
          XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
          XLSX.writeFile(workBook, `${filename}.xlsx`);
        };

        const saveAsXlsx = () => {
          exportToXLSX(`Joiners-${year}`, joinersProcessed, "Joiners");
          exportToXLSX(`Leavers-${year}`, leaversProcessed, "Leavers");
        };

        saveAsXlsx();
      } else {
        console.error("Data not found.");
        alert("Data not found.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Something went wrong");
    }
  };

  const handleDownloadJoinerLeaverPDF = async () => {
    const res: any = await fetchJoinerLeaver(formatYear(selectedDate));
    if (res?.res) {
      const joiners = res.res.joiners || [];
      const leavers = res.res.leavers || [];

      const doc = new jsPDF();

      doc.setFontSize(15);
      doc.text("Joiners Report", 14, 22);

      const joinersData = joiners?.map((joiner: any, index: any) => [
        index + 1,
        joiner?.empId,
        joiner?.name,
        joiner?.designation,
        joiner?.salary,
        joiner?.joiningDate,
      ]);

      if (joiners.length > 0) {
        autoTable(doc, {
          startY: 27,
          head: [
            [
              "Sr No",
              "Employee Id",
              "Name",
              "Designation",
              "Salary",
              "Joining Date",
            ],
          ],
          body: joinersData,
          didParseCell: function (data) {
            if (data.section === "body" && data.column.index === 2) {
              data.cell.styles.textColor = "#33475F";
            }
          },
        });

        const leaverHeadingY = 100;

        doc.setFontSize(15);
        doc.text("Leavers Report", 14, leaverHeadingY);

        const leaversData = leavers?.map((leaver: any, index: any) => [
          index + 1,
          leaver?.empId,
          leaver?.name,
          leaver?.designation,
          leaver?.salary,
          leaver?.joiningDate,
          leaver?.leavingDate,
          leaver?.totalService,
        ]);

        if (leavers.length > 0) {
          autoTable(doc, {
            startY: leaverHeadingY + 5,
            head: [
              [
                "Sr No",
                "Employee Id",
                "Name",
                "Designation",
                "Salary",
                "Joining Date",
                "Leaving Date",
                "Total Service",
              ],
            ],
            body: leaversData,
            // didParseCell: function (data) {
            //   if (data.section === "body" && data.column.index === 2) {
            //     data.cell.styles.textColor = "red";
            //   }
            // },
            headStyles: {
              fillColor: [255, 0, 0],
            },
          });
        }

        doc.save(`JoinerLeaver-${formatYear(selectedDate)}.pdf`);
      } else {
        alert("No Data Found for this Year");
      }
    }
  };

  useEffect(() => {
    getReportsData();
    getJoinerAndLeaver();
    console.log(selectedReport, "jfjf");
  }, []);
  const handleClear = () => {
    setSelectedDate(undefined);
    setReqAgain(!reqAgain);
  };

  const handleDownloadHistoryAppraisalExcel = async () => {
    try {
      const res: any = await fetchAppraisalHistory();
      if (res?.res) {
        const activePerkHistories: any[] = [];
        const inactivePerkHistories: any[] = [];

        res.res.forEach((employeeData: any) => {
          if (employeeData.contracts) {
            if (employeeData.status === "Inactive") {
              inactivePerkHistories.push(employeeData);
            } else {
              activePerkHistories.push(employeeData);
            }
          }
        });

        const processRows = (data: any[]) => {
          return data.flatMap((employeeData: any, employeeIndex: number) => {
            const employeeRows = employeeData.contracts.map(
              (contract: any, contractIndex: number) => ({
                "Sr No": contractIndex === 0 ? employeeIndex + 1 : "",
                "Employee Id": contractIndex === 0 ? employeeData.empId : "",
                Name: contractIndex === 0 ? employeeData.name : "",
                Status: contractIndex === 0 ? employeeData.status : "",
                Amount: currency + " " + formatAmount(contract.amount), // Format the amount here
                Designation: contract.designation,
                Since: contract.since,
                Till: contract.till,
              })
            );
            // Add an empty row after each employee's data
            employeeRows.push({
              "Sr No": "",
              "Employee Id": "",
              Name: "",
              Status: "",
              Amount: "",
              Designation: "",
              Since: "",
              Till: "",
            });
            return employeeRows;
          });
        };

        const activeHistoryProcessed = processRows(activePerkHistories);
        const inactiveHistoryProcessed = processRows(inactivePerkHistories);

        const exportToXLSX = (filename: string, data: any) => {
          const sheetName = "Report";
          const workBook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data);
          worksheet["!cols"] = [
            { wch: 15 },
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];
          XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
          XLSX.writeFile(workBook, `${filename}.xlsx`);
        };

        exportToXLSX("Active Perk History", activeHistoryProcessed);
        exportToXLSX("Inactive Perk History", inactiveHistoryProcessed);
      } else {
        console.error("No perk history data found for any employee.");
        alert("No perk history data found for any employee.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Something went wrong");
    }
  };

  
  const handleKeyDown = (e:any ) => {
    e.preventDefault(); // Prevent all key presses
  };


  return (
    <>
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div className="basis-full px-[15px] xl:px-[30px]">
          <EmployeeStatus
            heading="Reports"
            leadText="See all reports"
            StatusLogo={
              <ClipboardDocumentListIcon className="w-9 h-9 text-[#33475F]" />
            }
            Subscription=""
            showFirst={""}
            showSecond={false}
            Request={``}
            PendingDate={""}
          />
          <div className="basis-full md:basis-2/3">
            <div className="flex flex-col md:flex-row mt-5 gap-3 xl:gap-5">
              <div className="basis-full md:basis-1/3">
                <EmpReportsItem
                  reportsData={reportsData}
                  selectedReport={selectedReport}
                  setSelectedReport={setSelectedReport}
                />
              </div>
              <div className="basis-full md:basis-2/3 relative  ">
                {selectedReport === "Joiner & Leaver" ? (
                  <>
                  <div className="cursor-pointer flex float-right">
                    <ReactDatePicker
                      className="cursor-pointer rounded-lg min-w-[230px] bg-[#00000] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
                      dateFormat="yyyy"
                      placeholderText="Select Year"
                      showYearPicker={true}
                      maxDate={filterMaxDate}
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date as Date)}
                      preventOpenOnFocus        
                      onKeyDown={(e) => handleKeyDown(e)}


                    />

                    {selectedDate ? (
                      <button
                        className="absolute left-[983px] py-3"
                        onClick={handleClear}
                      >
                        <XMarkIcon className="text-black w-5 h-5" />
                      </button>
                    ) : (
                      <span className="absolute left-[983px] py-3 pointer-events-none">
                        <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
                      </span>
                    )}</div>
                    <div className="flex items-center gap-4 justify-center mx-auto text-center h-full">
                      <button
                        className="btnExport text-white rounded px-2 py-1 font-medium"
                        onClick={handleDownloadJoinerLeaverExcel}
                      >
                        Download Excel
                      </button>
                      <button
                        className="btnExport text-white rounded px-2 py-1 font-medium"
                        onClick={handleDownloadJoinerLeaverPDF}
                      >
                        Download PDF
                      </button>
                    </div>
                  </>
                ) : (
                  <ReportTable
                    getUserWithEmail={handleDownloadHistoryAppraisalExcel}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default EmpReports;
