import React, { useEffect, useState } from "react";
import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { IPerformanceData } from "../../types/interfaces/IPerformance.interface";
import { User } from "../../types/interfaces/IEmployeesManagement.interface";

interface Props {
  performanceData?: IPerformanceData[];
  selectedEmployeeId?: IPerformanceData;
  setSelectedEmployeeId?: Function;
}

const EmployeeList: React.FC<Props> = ({
  performanceData,
  selectedEmployeeId,
  setSelectedEmployeeId,
}) => {
  const [filteredEmps, setFilteredEmps] = useState<IPerformanceData[]>([]);
  const [searchEmp, setSearchEmp] = useState("");

  const handleSearchEmp = () => {
    const filteredEmps = performanceData?.filter((user) =>
      user?.userName?.toLowerCase().includes(searchEmp.toLowerCase())
    );
    setFilteredEmps(filteredEmps as IPerformanceData[]);
  };

  useEffect(() => {
    if (performanceData && performanceData.length > 0) {
      setFilteredEmps(performanceData);
    }
  }, [performanceData]);

  useEffect(() => {
    handleSearchEmp();
  }, [searchEmp]);

  return (
    <>
      <div>
        <h2 className="text-xl text-[#333333] mb-4 font-semibold">Employees</h2>
        <div className="relative flex items-center mb-4">
          <input
            className="cursor-text w-full py-[10px] ps-10 pe-3 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none"
            type="search"
            placeholder="Search"
            value={searchEmp}
            onChange={(event) => setSearchEmp(event?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
        </div>
        <div className="capitalize Employees border-[#EBEBEB] border rounded-2xl p-3 bg-white overflow-y-scroll md:h-[calc(100vh-375px)] h-[calc(100vh-615px)]">
          {filteredEmps?.map((user, index) => (
            <div
              key={index}
              className={`flex items-center justify-between hover:bg-[#F0F7FB] rounded-md py-4 px-3 cursor-pointer ${
                selectedEmployeeId?.userId === user?.userId && "bg-[#F0F7FB]"
              }`}
              onClick={() => {
                setSelectedEmployeeId && setSelectedEmployeeId(user);
              }}
            >
              <div className="flex items-center gap-3">
                <p className="text-sm text-[#5C5C5C] w-6">{index + 1}</p>
                <div>
                  <p className="text-sm text-[#33475F] font-semibold capitalize">
                    {user?.userName}
                  </p>
                  <p className="text-[10px] text-[#858585] font-semibold">
                    {user?.designation}
                  </p>
                </div>
              </div>
              {!user?.isEvaluated ? (
                <span className="bg-[#33475F] text-[10px] font-semibold text-white rounded-3xl px-2 py-0.5">
                  Pending
                </span>
              ) : (
                <span className="text-[10px] font-semibold text-white rounded-3xl px-2 py-0.5">
                  {" "}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
