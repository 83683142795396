import React from "react";
import { ICreateNotice } from "../../types/interfaces/INoticeBoard.interface";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleInput: (
    input:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => void;
  noticeBody: ICreateNotice;
  titleRef: React.MutableRefObject<HTMLInputElement | null>;
  descriptionRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  isTitleAvailable: boolean;
  setIsTitleAvailable: (check: boolean) => void;
  isDescriptionAvailable: boolean;
  setIsDescriptionAvailable: (check: boolean) => void;
  action: string;
  handleDiscard: () => void;
}

const AddNotice: React.FC<Props> = ({
  onSubmit,
  handleInput,
  noticeBody,
  titleRef,
  descriptionRef,
  isTitleAvailable,
  setIsTitleAvailable,
  isDescriptionAvailable,
  setIsDescriptionAvailable,
  action,
  handleDiscard
}) => {
  const handleBlur = (fieldName: string) => {
    if (fieldName === "title") {
      noticeBody?.title
        ? setIsTitleAvailable(true)
        : setIsTitleAvailable(false);
    } else {
      noticeBody?.description
        ? setIsDescriptionAvailable(true)
        : setIsDescriptionAvailable(false);
    }
  };

  return (
    <div className="bg-white border rounded-2xl p-2 lg:px-4 lg:py-5  h-[450px]">
      <form onSubmit={onSubmit}>
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl font-semibold text-[#33475F]">Announcement</h3>
        </div>
        <div className="pt-[10px]">
          <p className="text-base text-start font-normal text-[#3333333] mb-3 ">
            Add Announcement{" "}
          </p>
          <input
            className={`cursor-text bg-[#ffffff] w-full text-base text-[#333] p-3 border border-[#D6D6D6] rounded-lg ${
              !isTitleAvailable &&
              "border-red-600 focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500"
            }`}
            type="text"
            maxLength={200}
            name="title"
            placeholder="Title"
            value={noticeBody?.title}
            onChange={(event) => handleInput(event)}
            onBlur={() => handleBlur("title")}
            ref={titleRef}
          />
        </div>
        <div className="py-3">
          <textarea
            id="textareaField"
            name="description"
            placeholder="Description"
            className={`cursor-text block resize-none text-base font-asap font-normal text-[#858585] p-3 h-48 bg-[#ffffff] rounded-lg w-full border ${
              !isDescriptionAvailable &&
              "border-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:border-red-500"
            }`}
            onChange={(event) => handleInput(event)}
            onBlur={() => handleBlur("description")}
            value={noticeBody?.description}
            ref={descriptionRef}
          />
        </div>
        <div className="flex justify-end ">
          <button
            type="button"
            className="text-sm font-semibold text-black bg-[#ffffff] border border-gray-400 py-2 px-3 rounded-lg w-28 h-10"
            onClick={handleDiscard}
          >
            Discard
          </button>
          <button
            type="submit"
            className="mx-3 text-sm font-semibold text-white bg-[#378EB5] py-2 px-3 rounded-lg w-28 h-10"
          >
            {action === "edit" ? "Update" : "Send"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNotice;
