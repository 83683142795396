import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import StatusCard from "../../components/statusCard/StatusCard";
import PandingLeaveTable from "../../components/pandingLeave/PandingLeaveTable";
import AllNotices from "../../components/employeesAssets/AllNotices";
import { useEffect, useRef, useState } from "react";
import AddNotice from "../../components/NoticeBoard/AddNotice";
import {
  ICreateNotice,
  IDashboardEmpsInfo,
  INoticeBoard,
  NoticeDetails,
} from "../../types/interfaces/INoticeBoard.interface";
import {
  createNotice,
  fetchAllNotices,
  fetchEmpDashboardInfo as fetchDashboardEmpsInfo,
  removeNotice,
  updateNotice,
} from "../../services/noticeboard.service";
import { formatDateSlash } from "../../utils/dateFromatter";
import { toast } from "react-toastify";

const initNotice = {
  title: "",
  description: "",
  noticeID: "",
};

const Dashboard = () => {
  const [noticeBody, setNoticeBody] = useState<ICreateNotice>(initNotice);
  const [allNotices, setAllNotices] = useState<INoticeBoard>();
  const [noticeID, setNoticeID] = useState<string>("");
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];
  const [dashboardEmpsInfo, setDashboardEmpsInfo] =
    useState<IDashboardEmpsInfo>();
  const [searchQuery, setSearchQuery] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [isTitleAvailable, setIsTitleAvailable] = useState(true);
  const [isDescriptionAvailable, setIsDescriptionAvailable] = useState(true);
  let timeoutId: NodeJS.Timeout | null = null;
  const titleRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const allNoticesRef = useRef<HTMLDivElement | null>(null); // Create a ref for AllNotices

  const handleInput = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setNoticeBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setIsTitleAvailable(true);
    setAction("")
    setIsDescriptionAvailable(true);

    if (noticeBody.title && noticeBody.description) {
      const res = noticeID
        ? await updateNotice(noticeBody, noticeID)
        : await createNotice(noticeBody);
      if (res?.res) {
        setNoticeBody(initNotice);
        setNoticeID("");
        await getAllNotices();
        if (allNoticesRef.current) {
          allNoticesRef.current.scrollTop = 0; // Scroll to the top
        }
      }
    } else {
      toast.error("Title and description are required fields");
      if (descriptionRef.current && !noticeBody.description) {
        descriptionRef.current.focus();
        setIsDescriptionAvailable(false);
      }
      if (titleRef.current && !noticeBody.title) {
        titleRef.current.focus();
        setIsTitleAvailable(false);
      }
    }
  };

  const getAllNotices = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllNotices(startDate, endDate, searchQuery);
    if (res?.res) {
      setAllNotices(res?.res as INoticeBoard);
    }
  };

  const getDashboardEmpsInfo = async () => {
    const res = await fetchDashboardEmpsInfo();
    if (res?.res) {
      setDashboardEmpsInfo(res?.res as IDashboardEmpsInfo);
    }
  };

  const handleDeleteNotice = async (noticeID: string) => {
    const res = await removeNotice(noticeID);
    if (res?.res) {
      getAllNotices();
    }
  };
const [action , setAction]  = useState<string> ("")
  const handleNoticeAction = (noticeDetails: NoticeDetails, action: string) => {
    if (action === "edit") {
      setAction("edit")
      setNoticeBody({
        title: noticeDetails?.Title,
        description: noticeDetails?.Description,
      });
      setNoticeID(noticeDetails?.ID);
    } else if (action === "delete") {
      setAction('')
      handleDeleteNotice(noticeDetails?.ID);
    }
  };

  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getAllNotices(
            formatDateSlash(startDate),
            formatDateSlash(endDate),
            searchQuery
          );
        } else getAllNotices(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getAllNotices(formatDateSlash(startDate), formatDateSlash(endDate));
    } else {
      getAllNotices();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  useEffect(() => {
    getDashboardEmpsInfo();
  }, []);

  useEffect(() => {
    if (startDate || endDate || allNotices) {
      setNoticeBody(initNotice);
    }
  }, [startDate, endDate, allNotices]);

  function handleDiscard(){
    setAction("")
    setNoticeBody(initNotice);  }
  return (
    <>
      {/* <Header /> */}
      {/* <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div className="basis-full px-[15px] xl:px-[30px]">
          <StatusCard
            dashboardEmpsInfo={dashboardEmpsInfo as IDashboardEmpsInfo}
          />
          <div className="flex flex-col-reverse lg:flex-row gap-3 xl:gap-5">
            <div className="basis-full lg:basis-2/4">
              <AddNotice
                onSubmit={handleSubmit}
                handleInput={handleInput}
                noticeBody={noticeBody}
                titleRef={titleRef}
                descriptionRef={descriptionRef}
                isTitleAvailable={isTitleAvailable}
                setIsTitleAvailable={setIsTitleAvailable}
                isDescriptionAvailable={isDescriptionAvailable}
                setIsDescriptionAvailable={setIsDescriptionAvailable}
                action= {action}
                handleDiscard= {handleDiscard}
              />
            </div>
            <div className="basis-full lg:basis-2/4" ref={allNoticesRef}>
              <AllNotices
                allNotices={allNotices as INoticeBoard}
                handleNoticeAction={handleNoticeAction}
                setDateRange={setDateRange}
                startDate={startDate}
                endDate={endDate}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleClear={handleClear}
                scrollToTopRef={allNoticesRef} // Pass the ref to AllNotices
              />
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default Dashboard;
