import { msalConfig } from "./config/auth-config";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { setIsAuthenticated, signIn } from "./services/auth.service";
import { IMsal } from "./types/interfaces/IMsal.interface";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
export const msalInstance = new PublicClientApplication(msalConfig);


if (msalInstance.getActiveAccount()) {
  setIsAuthenticated(true);
}

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
    setIsAuthenticated(true);
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // TODO: Show appropriate error message here
  }
});
// msalInstance.initialize().then(() => {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   const accounts = msalInstance.getAllAccounts();
//   if (accounts.length > 0) {
//     msalInstance.setActiveAccount(accounts[0]);
//   }

//   msalInstance.addEventCallback((event: EventMessage) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//       const payload = event.payload as AuthenticationResult;
//       const account = payload.account;
//       msalInstance.setActiveAccount(account);
//     }
//   });
// });
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App msalInstance={msalInstance} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
