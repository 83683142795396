import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { FuelRateList } from "../../types/interfaces/FuelData";

interface Props {
  setShowFuelRate: Function;
  fuelRates: FuelRateList[];
  handleAllFuelRates: Function;
}

const FuelDataModel: React.FC<Props> = ({
  setShowFuelRate,
  fuelRates,
  handleAllFuelRates,
}) => {
  const currency = localStorage.getItem("currency") || "USD"; // Default to USD if not found
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setShowFuelRate(false));

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatDateRange = (dateFrom: string, dateTo: string) => {
    const from = new Date(dateFrom);
    const to = new Date(dateTo);

    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };
    const formattedFrom = from.toLocaleDateString("en-US", options);
    const formattedTo = to.toLocaleDateString("en-US", options);

    // Assuming both dates are in the same year
    const year = from.getFullYear();

    return `${formattedFrom} - ${formattedTo} ${year}`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={ref}
        className="bg-white rounded-lg shadow-lg p-5 w-full max-w-lg"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-800">
            Fuel Rates (per Ltr.)
          </h2>
          <button
            aria-label="Close modal"
            onClick={() => setShowFuelRate(false)}
          >
            <XMarkIcon className="w-6 h-6 text-gray-600 hover:text-gray-800" />
          </button>
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-[rgb(225,225,225)]">
                <th className="py-2 px-4 border-b border-black-200 text-left text-xs font-bold text-black-600 uppercase tracking-wider">
                  Date Range
                </th>
                <th className="py-2 px-4 border-b border-black-200 text-left text-xs font-bold text-black-600 uppercase tracking-wider">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {fuelRates.map((rate) => (
                <tr key={rate.ID} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-black-200 text-sm text-black-700">
                    {formatDateRange(rate.DateFrom, rate.DateTo)}
                  </td>
                  <td className="py-2 px-4 border-b border-black-200 text-sm text-black-700">
                    {formatCurrency(rate.Amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FuelDataModel;
