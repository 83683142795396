import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { MedicalRequest } from "../../types/interfaces/IMedicalAllowance.interface";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  comments?: string;
  handleComments?: (comment: string) => void;
  actionToPerform?: string;
  sendRequest?: (amount: number) => void;
  record?: MedicalRequest;
}

const ApproveWithAmount: React.FC<Props> = ({
  isOpen,
  onClose,
  comments,
  handleComments,
  actionToPerform,
  sendRequest,
  record,
}) => {
  const [amount, setAmount] = useState<number | undefined>(
    record?.medicalRequest.AmountApplied
  );

  useEffect(() => {
    if (isOpen) {
      setAmount(record?.medicalRequest.AmountApplied);
    }
  }, [isOpen, record?.medicalRequest.AmountApplied]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  const handleSubmit = () => {
    if (actionToPerform && actionToPerform === "rejected") {
      sendRequest && sendRequest(0);
      return;
    }
    if (
      record?.medicalRequest.AmountApplied &&
      amount &&
      amount > record?.medicalRequest.AmountApplied
    ) {
      return;
    }
    if (!amount) {
      toast.error("Enter some Amount");
      return;
    }
    sendRequest && sendRequest(amount as number);
  };

  const currency = localStorage.getItem("currency") || "USD"; // Default to USD if not found

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const parseCurrency = (value: string) => {
    return parseInt(value.replace(/[^0-9]+/g, ""), 10);
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 z-20 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div ref={ref} className="bg-white rounded-lg shadow-md w-[690px]">
        <div className="flex justify-between items-center px-4 py-2">
          <h3 className="text-base font-semibold text-[#171717]">
            {actionToPerform && actionToPerform === "accepted"
              ? "Approve"
              : "Reject"}{" "}
            Request
          </h3>
          <button onClick={onClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="p-3">
          {actionToPerform === "accepted" && (
            <>
              <p className="text-base text-start font-normal text-[#3333333] mb-3">
                Set Approved Amount{" "}
              </p>
              <input
                className="bg-[#EBEBEB] w-64 text-sm text-black border border-[#D6D6D6] rounded-lg p-3"
                type="text"
                placeholder="Amount"
                value={amount !== undefined ? formatCurrency(amount) : ""}
                onChange={(event) => {
                  const parsedAmount = parseCurrency(event.target.value);
                  setAmount(!isNaN(parsedAmount) ? parsedAmount : undefined);
                }}
              />
              {amount && amount > record?.medicalRequest.AmountApplied! ? (
                <p className="bg-red text-red-600">
                  Approved amount can't be greater than applied amount
                </p>
              ) : null}
            </>
          )}
        </div>
        <div className="p-3">
          <p className="text-base text-start font-normal text-[#3333333] mb-3">
            {actionToPerform === "accepted"
              ? "Add comments before approve"
              : "Add comments before reject"}
          </p>
          <textarea
            id="textareaField"
            name="textareaField"
            placeholder="Add comments"
            className="block text-base font-asap font-normal text-black p-3 h-28 bg-[#EBEBEB] rounded-md w-full"
            onChange={(event) => {
              handleComments && handleComments(event?.target?.value);
            }}
            value={comments}
          />
        </div>
        <div className="flex justify-end items-center gap-2 p-4">
          <button
            onClick={onClose}
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
          >
            Cancel
          </button>
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg w-[76px] h-10"
            onClick={handleSubmit}
          >
            {actionToPerform && actionToPerform === "accepted"
              ? "Approve"
              : "Reject"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveWithAmount;
