import React, { useState, useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { toast } from "react-toastify";
import { generatePaySlip } from "../../services/paySlips.service";
import exportFromJSON from "export-from-json";
import { monthNames } from "../../utils/monthsArray";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  getEmp: Function;
  month_Year?:Date | null;
}

const GenratePayslipModal: React.FC<Props> = ({ isOpen, onClose, getEmp , month_Year }) => {

  

  const [reqAgain, setReqAgain] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [monthYear, setMonthYear] = useState<{ month: number; year: number }>();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setSelectedDate(null);
    onClose();
  };

  const handleClear = () => {
    setSelectedDate(null);
    setReqAgain(!reqAgain);
  };

  const dateHandler = (date: Date) => {
    setSelectedDate(date);
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    setMonthYear({ month, year });
  };

  const paySlipGenerator = async () => {
    if (monthYear?.month && monthYear.year) {
      const res = await generatePaySlip(monthYear.month, monthYear.year);
      if (res) {
        handleClose();
        if (res.err) {
          toast.error("PaySlip not Found");
        } else {
          toast.success("Successfully Generated");

          const data = res.res?.payslips;
          const csvDataMeezanBank =
            data &&
            data
              .map((payslip) => {
                if (payslip.EmployeeDetails.BankName === "MEEZAN BANK LIMITED") {
                  return {
                    ACCOUNTNUMBER: `'${payslip.EmployeeDetails.AccountNo}`,
                    BENEFICAIRY_NAME: payslip.EmployeeDetails.Name,
                    CUSTOMERREFERENCENUMBER: payslip.EmployeeDetails.EmployeeReferenceNo,
                    TRANSAMOUNT: payslip.AfterDeductionSalary,
                  };
                } else {
                  return null;
                }
              })
              .filter(Boolean);
          const csvDataAllBank =
            data &&
            data
              .map((payslip) => {
                if (payslip.EmployeeDetails.BankName !== "MEEZAN BANK LIMITED") {
                  return {
                    ACCOUNTNUMBER: `'${payslip.EmployeeDetails.AccountNo}`,
                    BENEFICAIRY_NAME: payslip.EmployeeDetails.Name,
                    CUSTOMERREFERENCENUMBER: payslip.EmployeeDetails.EmployeeReferenceNo,
                    TRANSAMOUNT: payslip.AfterDeductionSalary,
                    BANK_CODE: `'${payslip.EmployeeDetails.BankCode}`,
                  };
                } else {
                  return null;
                }
              })
              .filter(Boolean);
          const fileNameMeezanBank = `${monthNames[monthYear.month - 1]}_${monthYear.year}_Salaries_IFT`;
          const fileNameAllBank = `${monthNames[monthYear.month - 1]}_${monthYear.year}_Salaries_IBFT`;

          const exportType = exportFromJSON.types.csv;
          if (csvDataMeezanBank) {
            exportFromJSON({
              data: csvDataMeezanBank,
              fileName: fileNameMeezanBank,
              exportType,
            });
          }
          if (csvDataAllBank) {
            exportFromJSON({
              data: csvDataAllBank,
              fileName: fileNameAllBank,
              exportType,
            });
          }
          getEmp();
        }
      } else {
        // Handle response error
      }
    } else {
      // Handle validation error
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };

  useEffect(() => {
    setSelectedDate(month_Year);
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 z-20 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div ref={modalRef} className="bg-white rounded-lg shadow-md w-[690px]">
        <div className="flex justify-between items-center px-4 py-4">
          <h3 className="text-base font-semibold text-[#171717]">Generate Payroll</h3>
          <button onClick={handleClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="p-3">
          <p className="text-base font-normal text-[#3333333] mb-3">Select Month or Year</p>

          <div className="cursor-pointer relative md:mb-0 flex items-center z-10 gap-2">
            <ReactDatePicker
              className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#EBEBEB] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              dateFormat="MMMM yyyy"
              placeholderText="Select Month"
              showMonthYearPicker
              maxDate={moment().month(new Date().getMonth() - 1).toDate()}
              selected={selectedDate}
              onChange={(date) => dateHandler(date as Date)}
              preventOpenOnFocus
            />
            {selectedDate ? (
              <button className="absolute top-2.5 right-4" onClick={handleClear}>
                <XMarkIcon className="w-6 h-6" />
              </button>
            ) : (
              <span className="cursor-pointer absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-end items-center gap-2 p-4">
          <button
            onClick={handleClose}
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
          >
            Cancel
          </button>
          <button
            onClick={paySlipGenerator}
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-1 rounded-lg w-[72px] h-10"
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenratePayslipModal;
