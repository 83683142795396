import React, { useEffect, useRef, useState } from "react";
import {
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import TablePagination from "../pagination/TablePagination";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { IWOH, WorkOnHoliday } from "../../types/interfaces/IWOH.interface";
import {
  fetchAllWOHRequests,
  updateWOHStatus,
} from "../../services/workOnHoliday.service";
import {
  formatDateMonth,
  formatDateSpace,
  formatDateYear,
} from "../../utils/dateFromatter";
import moment from "moment";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import { scrollToTop } from "../../utils/tableScroller";
import { toast } from "react-toastify";
interface Props {
  getWOHCountInfo: () => void;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  getAllWOHRequests: Function;
  WOHRequest: IWOH;
}
const WorkOnHolidayTable: React.FC<Props> = ({
  getWOHCountInfo,
  openPopoverId,
  setOpenPopoverId,
  getAllWOHRequests,
  WOHRequest,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<WorkOnHoliday>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const handleWOHAction = async (WOHReqData: WorkOnHoliday) => {
    const reqBody = { comment: comments };
    const res = await updateWOHStatus(
      WOHReqData?.workOnHolidayRequest?.HolidayRequestID,
      actionToPerform,
      reqBody
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      if (actionToPerform === "rejected") {
        toast.success("Work on Holiday Request Rejected Successfully");
      } else {
        toast.success("Work on Holiday Request Approved Successfully");
      }

      getWOHCountInfo();

      closeModal();
    }
  };
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleComments = (comments: string) => {
    setComments(comments);
  };

  useEffect(() => {
    if (!selectedDate) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [selectedDate]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (selectedDate) {
          getAllWOHRequests(
            formatDateMonth(selectedDate),
            formatDateYear(selectedDate),
            searchQuery
          );
        } else getAllWOHRequests(undefined, undefined, searchQuery);
      }, 1000);
    } else if (selectedDate) {
      getAllWOHRequests(
        formatDateMonth(selectedDate),
        formatDateYear(selectedDate)
      );
    } else {
      getAllWOHRequests();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  const openModal = (event: any, record: WorkOnHoliday) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const togglePopover = (record: WorkOnHoliday) => {
    if (openPopoverId === record.workOnHolidayRequest.HolidayRequestID) {
      setOpenPopoverId(null);
    } else {
      setOpenPopoverId(record.workOnHolidayRequest.HolidayRequestID);
    }
    setSelectedRecord(record);
  };
  const handleClear = () => {
    setSelectedDate(undefined);

    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);

  const handleKeyDown = (e:any ) => {
    e.preventDefault(); // Prevent all key presses
  };
  
  
  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center z-10 gap-2">
            <ReactDatePicker
              className="cursor-pointer rounded-lg min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              dateFormat="MMMM yyyy"
              placeholderText="Select Month"
              showMonthYearPicker
              selected={selectedDate}
              onChange={(date) => handleDateChange(date as Date)}
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}

            />
            {selectedDate ? (
              <button className="absolute top-3 right-4" onClick={handleClear}>
                <XMarkIcon className="text-black w-5 h-5" />
              </button>
            ) : (
              <span className="absolute  top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
        </div>
        {WOHRequest?.WorkOnHolidays?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-325px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Holiday Name</th>
                    <th>Work date</th>
                    <th>Reason</th>
                    <th>Date Applied</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {WOHRequest?.WorkOnHolidays?.map((req, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.userName}</td>
                      <td data-cell="Holiday Name">
                        {req?.workOnHolidayRequest?.Holiday}
                      </td>
                      <td data-cell="Work date">
                        {formatDateSpace(req?.workOnHolidayRequest?.Date)}
                      </td>
                      <td data-cell="Reason">
                        <div className="relative">
                          <button
                            onClick={() => togglePopover(req)}
                            className={`text-sm text-[#378EB5] font-semibold ${
                              openPopoverId &&
                              openPopoverId?.length > 0 &&
                              (openPopoverId ===
                              req.workOnHolidayRequest.HolidayRequestID
                                ? ""
                                : "cursor-default")
                            }`}
                          >
                            <ChatBubbleBottomCenterTextIcon className="text-#378EB5 w-5 h-5" />
                          </button>
                          {openPopoverId ===
                            req.workOnHolidayRequest.HolidayRequestID && (
                            <ReasonPopOver
                              reason={
                                selectedRecord?.workOnHolidayRequest
                                  ?.Reason as string
                              }
                              onClose={() => setOpenPopoverId(null)}
                            />
                          )}
                        </div>
                      </td>
                      <td data-cell="Date Applied">
                        {formatDateSpace(
                          moment(req?.workOnHolidayRequest?.CreatedAt).format(
                            "DD/MM/YYYY"
                          )
                        )}
                      </td>
                      <td>
                        <div className="flex justify-end items-center gap-1">
                          {req?.workOnHolidayRequest?.Status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="accepted"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.workOnHolidayRequest?.Status ===
                            "accepted" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <TablePagination /> */}

            <ApproveRejectModal
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={handleWOHAction}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Work on Holiday Request Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkOnHolidayTable;
