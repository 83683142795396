import React from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import AdminProfile from "../../components/Profile/AdminProfile";

const Profile = () => {
  return (
    <div className="basis-full px-[15px] xl:px-[30px]">
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
      <div>
        <div className="basis-full md:basis-2/3">
          <AdminProfile />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Profile;
