import { toast } from "react-toastify";
import { IUpdateLeaveStatus } from "../types/interfaces/ILeaveRecords.interface";
import { api } from "../utils/axios";

export const fetchAllSubs = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/weeklyLunchSubscription/getAllSubscriptions?${
        search ? "search=" + search : ""
      }${startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const subscribeToLunch = async () => {
  try {
    const { data, ok } = await api.post(
      `/weeklyLunchSubscription/subscribeToLunch`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const unSubscribeToLunch = async (subId: string) => {
  try {
    const { data, ok } = await api.patch(
      `/weeklyLunchSubscription/unsubscribeLunchAdmin/${subId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
  
    return { res: null, err: error };
  }
};

export const fetchLunchSubsInfo = async () => {
  try {
    const { data, ok } = await api.get(
      `/weeklyLunchSubscription/getLunchPriceAndTotalSubscriptions`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateLaunchPrice = async (price: number | undefined) => {
  try {
    const { data, ok } = await api.put(
      `/weeklyLunchSubscription/changeLunchPrice/${price}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};


