import React, { useRef, useState } from "react";
import MenuModal from "../custom-modal/MenuModal";
import { User } from "../../types/interfaces/IEmployeesManagement.interface";
import { IAddPerk } from "../../services/perk.service";
import AddPerkHistory from "./AddPerkHistory";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface WorkHistoryProps {
  isModalOpen: boolean;
  closeModal: () => void;
  workHistoryData: IAddPerk[];
  selectedEmployee: User;
  setIsModalOpen: Function;
  addPerk: Function;
  deletePerk:Function;
}

const WorkHistory: React.FC<WorkHistoryProps> = ({
  isModalOpen,
  closeModal,
  workHistoryData,
  selectedEmployee,
  setIsModalOpen,
  addPerk,
  deletePerk
}) => {
  const [openYear, setOpenYear] = useState<string | null>(null);
  const [openDelteModal,setOpenDeleteModal] = useState<boolean>(false);
  const [activeId,setActiveId] = useState<string>('')
  const groupByYear = (data: IAddPerk[]) => {
    return data.reduce((acc: Record<string, IAddPerk[]>, item) => {
      const [day, month, year] = item.since.split("/").map(Number); // Split 'dd/mm/yyyy' and parse the year
      const yearStr = year.toString(); // Get the year as a string
      if (!acc[yearStr]) {
        acc[yearStr] = [];
      }
      acc[yearStr].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupByYear(workHistoryData);

  // Toggle the accordion for each year
  const toggleYear = (year: string) => {
    setOpenYear(openYear === year ? null : year);
  };

  const [isAddModal, setIsAddModal] = useState(false);

  const isAddModalOpen = () => {
    setIsAddModal(true);
    closeModal();
  };

  const isCloseModal = () => {
    setIsAddModal(false);
    setIsModalOpen(true);
  };

  
  const ref: any = useRef();
  useOnClickOutside(ref, () => setOpenDeleteModal(false));
  return (
    <>
      <MenuModal openModal={isModalOpen} closeModal={closeModal}>
        <div className="flex flex-col">
          <div className="border-b border p-4">
            <h2 className="font-semibold text-xl">Perk History</h2>
          </div>
          <div className="p-4 space-y-3">
            <div className="flex items-center justify-between">
              <h4 className="font-bold"> {selectedEmployee?.Name}</h4>

              <button
                className="text-sm inline-flex items-center gap-2 font-semibold text-[#378EB5] border border-[#378EB5] rounded-lg px-4 py-2.5  hover:bg-[#0891B2] hover:text-white"
                onClick={isAddModalOpen}
              >
                Add Perk History
              </button>
            </div>

            {Object.keys(groupedData).map((year) => (
              <div key={year}>
                <button
                  onClick={() => toggleYear(year)}
                  className="w-full flex items-center justify-between text-left font-semibold text-lg py-2 px-4 border border-b  bg-white"
                >
                  {year}
                  <span></span>
                </button>
                {openYear === year && (
                  <div className="space-y-3 ">
                    {groupedData[year].map((item, index) => (
                      <div
                        key={index}
                        className="p-3 bg-white border border-gray-100 rounded-md relative"
                      >
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h4>Designation: </h4>
                            <span className="block font-semibold">
                              {item.designation}
                            </span>
                          </div>
                          <div>
                            <h4>Amount: </h4>
                            <span className="block font-semibold">
                              {item.amount}
                            </span>
                          </div>
                          <div>
                            <h4>Since:</h4>
                            <span className="block font-semibold">
                              {item.since}
                            </span>
                          </div>
                          <div>
                            <h4>Till: </h4>
                            <span className="block font-semibold">
                              {item.till}
                            </span>
                          </div>
                        </div>
                        <button
                          className="absolute top-3 right-3 text-sm text-white font-semibold bg-[#B91C1C] py-2.5 px-3 border rounded-lg flex items-center"
                          onClick={() => {
                            // deletePerk(item.id)
                            setActiveId(item?.id as string)
                            setOpenDeleteModal(true)
                          }}
                        >
                          <TrashIcon className="w-3 h-3" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </MenuModal>
      <AddPerkHistory
        isCloseModal={isCloseModal}
        isAddModal={isAddModal}
        addPerk={addPerk}
        selectedEmployee={selectedEmployee}
      />
        {openDelteModal && (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-50">
          <div ref={ref} className="bg-white rounded-lg p-5 w-[365px]">
            <h2 className="text-lg font-semibold mb-4">Confirm?
            </h2>
            <p className="mb-4">Are you sure you want to delete this perk?</p>

            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white rounded px-4 py-2"
                  onClick={()=>{
                      deletePerk(activeId);
                      setOpenDeleteModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkHistory;
