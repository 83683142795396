import { Subject } from "rxjs";
import { api } from "../utils/axios";
export const delteData$ = new Subject();
export const saveData$ = new Subject();
export const refreshData$ = new Subject();
export const updateData$ = new Subject();

export const fetchEmpsForPerformance = async () => {
  try {
    const { data, ok } = await api.get(
      `/performanceManagement/getAllUsersWithEvaluationStatus`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchEmpEvalForm = async (userId: string) => {
  try {
    const { data, ok } = await api.get(
      `/performanceManagement/getEvaluations/${userId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchEmpEvalTemplate = async () => {
  try {
    const { data, ok } = await api.get(
      `/performanceManagement/getPerformanceTemplate`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchTotalEmpData = async () => {
  try {
    const { data, ok } = await api.get(
      `/performanceManagement/getEmployeesRated`
    );
    if (ok) {
      refreshData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
interface ISaveEvaData {
  code: number;
  feedback: string;
}
export const SaveEmpEvaData = async (body: ISaveEvaData[], userId: string) => {
  try {
    console.log("bodybody", body);
    console.log("userId", userId);

    const { data, ok } = await api.post(
      `/performanceManagement/ratePerformance/${userId}`,
      body
    );
    if (ok) {
      saveData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchEvaByYear = async (userId: string, year?: number) => {
  try {
    const { data, ok } = await api.get(
      `/performanceManagement/getEvaluationsByYear/${userId}?${
        year ? `year=${year}` : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const deleteEvaYearModal = async (evalId: string) => {
  try {
    const { data, ok } = await api.delete(
      `/performanceManagement/deleteEvaluation/${evalId}
      `
    );
    delteData$.next(true);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const UpdateEmpEvaData = async (
  ID: string,
  userId: string,
  evaluation: string[]
) => {
  try {
    console.log("bodybody", ID, evaluation);
    console.log("userId", userId);
    const evaluationWithFeedback = evaluation.sort((a: any, b: any) =>
      a.code.localeCompare(b.code)
    );

    // Prepare the request body
    const requestBody = {
      ID,
      userId,
      evaluationWithFeedback,
    };
    // Make the PUT request with the appropriate headers
    const { data, ok } = await api.put(
      "/performanceManagement/updateEvaluation",
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (ok) {
      updateData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
