import { api } from "../utils/axios";

export const fetchAllAssets = async (
  startDate?: string,
  endDate?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/assetRequisition/getAllRecordsOfAssetRequisition?${
        search ? "search=" + search : ""
      }${startDate ? "&startDate=" + startDate + "&endDate=" + endDate : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchAssetsInfo = async () => {
  try {
    const { data, ok } = await api.get(
      `/assetRequisition/getTotalAssetsProvided`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateAssetReqStatus = async (
  status: string,
  assetReqId: string,
  reqBody: { comment: string }
) => {
  try {
    const { data, ok } = await api.patch(
      `/assetRequisition/approveOrDeclineRequest/${assetReqId}/${status}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const exportAssetsCSV = async () => {
  try {
    const { data, ok } = await api.post(`/assetRequisition/exportCSV`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};



export const updateTransferStatus = async ( id:string ,  type : string )=>{
  try{
  const {data , ok } =  await api.patch(`/assetRequisition/updateTransferStatus/${id}/${type}`)
  if (ok) {
    return { res: data, err: null };
  }
}
 catch (error) {
  return { res: null, err: error };
}
 }