import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef } from "react";

interface Props {
  record: string[];
  onClose: () => void;
}

const AssetsPopOver: React.FC<Props> = ({ record, onClose }) => {
  const [positionAbove, setPositionAbove] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickInsideModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent click from propagating to parent elements
  };

  useEffect(() => {
    const adjustPosition = () => {
      if (modalRef.current) {
        const rect = modalRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (rect.bottom > viewportHeight) {
          setPositionAbove(true);
        } else {
          setPositionAbove(false);
        }
      }
    };

    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    window.addEventListener("scroll", adjustPosition);

    return () => {
      window.removeEventListener("resize", adjustPosition);
      window.removeEventListener("scroll", adjustPosition);
    };
  }, []);

  return (
    <div
      ref={modalRef}
      className={`absolute ${
        positionAbove
          ? "top-auto bottom-full mb-2"
          : "top-1/2 transform translate-y-[8%] translate-x-[-88%]"
      } z-10 bg-white border border-gray-300 shadow-3xl rounded-md w-[150px] sm:w-[200px] lg:w-[250px]`}
      onClick={handleClickInsideModal}
    >
      <div className="flex justify-between items-center px-4 py-4">
        <h3 className="text-base font-semibold text-[#171717]">Assets</h3>
        <button onClick={onClose}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>
      <hr />
      <ul className="AssetsOrderList overflow-y-scroll h-36">
        {record?.map((assetName, index) => (
          <li
            key={index}
            className="p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg flex items-center gap-4"
          >
            <span className="text-sm text-[#5C5C5C] w-7">{index + 1}</span>
            <p className="text-sm text-[#333333]">{assetName}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AssetsPopOver;
