import { api } from "../utils/axios";

export const fetchAllAdvSalary = async (
  month?: string,
  year?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/salaryrequest/FindAll?${search ? "search=" + search : ""}${
        month ? "&month=" + month + "&year=" + year : ""
      }`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateAdvSalaryStatus = async (
  status: string,
  salaryId: string,
  reqBody: { comment: string }
) => {
  try {
    const { data, ok } = await api.patch(
      `/salaryrequest/respondSalaryRequest/${status}/${salaryId}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchSalariesCountInfo = async (date: string) => {
  try {
    const { data, ok } = await api.get(
      `/salaryrequest/FindApprovedAndTotalSalaries`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};


export const updatePaymentStatus = async (
  id: string,
) => {
  try {
    const { data, ok } = await api.patch(
      `/salaryrequest/markSalaryReqAsPaid/${id}`    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
