import { api } from "../utils/axios";

export const fetchAllWOHRequests = async (
  month?: string,
  year?: string,
  search?: string
) => {
  try {
    const { data, ok } = await api.get(
      `/holidayRequest/getAllHolidayRequests?${
        search ? "search=" + search : ""
      }${month ? "&month=" + month + "&year=" + year : ""}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchWOHRequests = async () => {
  try {
    const { data, ok } = await api.get(
      `/holidayRequest/getAcceptedAndPendingCount`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const fetchWOHEmpCount = async () => {
  try {
    const { data, ok } = await api.get(`/holidayRequest/getTodayWorkOffCount`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateWOHStatus = async (
  wohID: string,
  status: string,
  reqBody: { comment: string }
) => {
  try {
    const { data, ok } = await api.patch(
      `/holidayRequest/respondHoliday/${wohID}/${status}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
