import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { BanknotesIcon } from "@heroicons/react/24/solid";
import AdvanceSalaryTable from "../../components/Table/AdvanceSalaryTable";
import { fetchAllAdvSalary, fetchSalariesCountInfo } from "../../services/advanceSalary.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { ISalariesCountInfo, ISalaryRecords } from "../../types/interfaces/ISalaryRecords.interface";

const AdvanceSalary = () => {
  const [salariesCountInfo, setSalariesCountInfo] =
    useState<ISalariesCountInfo>();
  const [pendingReq, setPendingReq] = useState<number>(0);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [advSalaryRequests, setAdvSalaryRequests] = useState<ISalaryRecords>({
    Salaries: [],
    totalCount:0,
    approvedCount:0
  });
  const getSalariesCountInfo = async () => {
    const res = await fetchSalariesCountInfo(moment().format("DD/MM/YYYY"));
    if (res?.res) {
      setSalariesCountInfo(res?.res as ISalariesCountInfo);
    }
  };

  useEffect(() => {
    getSalariesCountInfo();
  }, []);

  useEffect(() => {
    if (!salariesCountInfo?.TotalSalaries) return;
    setPendingReq(
      salariesCountInfo?.TotalSalaries - salariesCountInfo?.Approved
    );
  }, [salariesCountInfo]);

  const getAllAdvSalary = async (
    month?: string,
    year?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllAdvSalary(month, year, searchQuery);
    if (res?.res) {
      setAdvSalaryRequests(res?.res as ISalaryRecords);
    }
  };
  
  return (
    <div
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
      className="basis-full px-[15px] xl:px-[30px]"
    >
      {/* <Header /> */}
      {/* <div className="flex flex-row"> */}
        {/* <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div className="">
          <EmployeeStatus
            showFirst={true}
            showSecond={false}
            heading="Advance Salary"
            leadText="Manage advance salary requests of employees"
            StatusLogo={<BanknotesIcon className="w-9 h-9 text-[#33475F]" />}
            Subscription="Approved Requests"
            price={advSalaryRequests.approvedCount}
            Request={"/" + advSalaryRequests.totalCount}
            TotalSubscriptions="Pending Requests"
            Total={advSalaryRequests.totalCount}
         
          />
          <div className="basis-2/3">
            <AdvanceSalaryTable
              getSalariesCountInfo={getSalariesCountInfo}
              openPopoverId={openPopoverId}
              setOpenPopoverId={setOpenPopoverId}
              advSalaryRequests={advSalaryRequests} 
              setAdvSalaryRequests={setAdvSalaryRequests}
              getAllAdvSalary={getAllAdvSalary}
            />
          </div>
        </div>
      </div>
    // </div>
  );
};

export default AdvanceSalary;
