import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  btnTitle: string;
  description: string;
  recordData?: any;
  actionToPerform?: string;
  sendRequest?: (recordData: any) => void;
}

const SuggestionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  description,
  recordData,
  actionToPerform,
  sendRequest,
  btnTitle,
}) => {
  const actionButton =
    actionToPerform === "accepted" || actionToPerform === "approved"
      ? "Approve"
      : "Reject";

  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 z-10 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg shadow-md w-[400px]">
        <div className="flex justify-between items-center px-4 py-2">
          <h3 className="text-base font-semibold text-[#171717] text-center flex-grow">
            {title}
          </h3>
          <button onClick={onClose}>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <hr />
        <div className="p-3">
          <p className="text-base font-normal text-[#3333333] mb-3">
            {description}
          </p>
        </div>
        <div className="flex justify-center items-center gap-5 p-4 ">
          <button
            onClick={onClose}
            className="text-sm font-semibold text-[#333333] py-2.5 px-3 rounded-lg border-[#D4D4D4] border w-[72px]"
          >
            Cancel
          </button>
          <button
            className="text-sm font-semibold text-white bg-[#378EB5] py-2.5 px-3 rounded-lg w-[80px] h-10"
            onClick={() => sendRequest && sendRequest(recordData)}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuggestionModal;
