import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  TruckIcon,
} from "@heroicons/react/24/solid";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import React from "react";
import { IDashboardEmpsInfo } from "../../types/interfaces/INoticeBoard.interface";
interface Props {
  dashboardEmpsInfo: IDashboardEmpsInfo;
}
const StatusCard: React.FC<Props> = ({ dashboardEmpsInfo }) => {
  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-3 xl:gap-5 mb-5 lg:mb-11">
        <div className="h-[160px] rounded-[22px] bg-white flex justify-center text-center items-center">
          <div className="flex justify-between text-center items-start w-full px-2 sm:px-4">
            <div className="text-start">
              <h3 className="font-semibold text-3xl text-[#333]">
                {dashboardEmpsInfo?.totalEmployees}
              </h3>
              <p className="text-base text-[#333] font-semibold">
                Total Employees
              </p>
            </div>
            <span className="bg-[#D6DADF] p-[6px] rounded-lg">
              <UserGroupIcon className="text-black w-5 h-5 " />
            </span>
          </div>
        </div>
        <div className="h-[160px] rounded-[22px] bg-white flex justify-center text-center items-center">
          <div className="flex justify-between text-center items-start w-full px-2 sm:px-4">
            <div className="text-start">
              <h3 className="font-semibold text-3xl text-[#333]">
                {dashboardEmpsInfo?.pendingLeaves}
              </h3>
              <p className="text-base text-[#333] font-semibold">
                Pending Leaves
              </p>
            </div>
            <span className="bg-[#D6DADF] p-[6px] rounded-lg">
              <CalendarDaysIcon className="text-black w-5 h-5 " />
            </span>
          </div>
        </div>
        <div className="h-[160px] rounded-[22px] bg-white flex justify-center text-center items-center">
          <div className="flex justify-between text-center items-start w-full px-2 sm:px-4">
            <div className="text-start">
              <h3 className="font-semibold text-3xl text-[#333]">
                {dashboardEmpsInfo?.pendingMedical}
              </h3>
              <p className="text-base text-[#333] font-semibold">
                Pending Medical
              </p>
            </div>
            <span className="bg-[#D6DADF] p-[6px] rounded-lg">
              <BuildingOfficeIcon className="text-black w-5 h-5 " />
            </span>
          </div>
        </div>
        {/* <div className="statuscard  flex justify-center text-center items-center">
          <div className="flex justify-between text-center items-start w-full px-2 sm:px-4">
            <div className="text-start">
              <h3 className="font-semibold text-3xl text-[#333]">
                {dashboardEmpsInfo?.pendingFuel}
              </h3>
              <p className="text-base text-[#333] font-semibold">
                Pending Fuel
              </p>
            </div>
            <span className="bg-[#D6DADF] p-[6px] rounded-lg">
              <TruckIcon className="text-black w-5 h-5 " />
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default StatusCard;
