import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import {
  fetchAllSubs,
  subscribeToLunch,
  unSubscribeToLunch,
} from "../../services/lunchManagement.service";
import { ILunchSubs } from "../../types/interfaces/ILunchSubs.interface";
import { formatDateSlash, formatDateSpace } from "../../utils/dateFromatter";
import moment from "moment";
import {
  fetchEligibleUser,
  fetchEligibleUserForBank,
  fetchEligibleUserForJInter,
  fetchUserWithEmail,
} from "../../services/empReports.service";
import { IReportUser } from "../../types/IReportUser";
import { IEligibleUSer } from "../../types/interfaces/IEligbleUser";
import {
  fetchUserDataBankCert,
  fetchUserDataExpCert,
} from "../../services/certificate.service";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { IBankUSers } from "../../types/interfaces/IBankUsers";
import { storageKeys } from "../../constants/storage.constants";

interface Props {
  getUserWithEmail: () => void;
}

const InternCertificate: React.FC<Props> = ({ getUserWithEmail }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState<IBankUSers>();
  const [reqAgain, setReqAgain] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];
  const accessToken =
    localStorage.getItem(storageKeys?.rememberMe) === "true"
      ? localStorage.getItem(storageKeys?.acToken)
      : localStorage.getItem(storageKeys?.acToken) || "";

  let timeoutId: NodeJS.Timeout | null = null;

  const getAllUser = async (query = "") => {
    const res = await fetchEligibleUserForJInter(); // Assuming fetchEligibleUser fetches all users
    if (res?.res) {
      let filteredUsers = res?.res as IBankUSers;

      // Apply frontend filtering based on search query
      if (query) {
        filteredUsers = filteredUsers.filter((user: any) =>
          user.name.toLowerCase().includes(query.toLowerCase())
        );
      }

      setUserData(filteredUsers);
    }
  };

  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAllUser(searchQuery);
      }, 500);
    } else {
      getAllUser();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
    getAllUser();
  };

  return (
    <>
      <div className="py-5 ">
      <div className="relative flex md:flex-row flex-col mt-6 md:items-center md:justify-between mb-3 md:mb-6 gap-3">
          <input
            className="w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#00000] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
        </div>
        {userData && userData?.length > 0 ? (
          <>
            <div className="capitalize Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-350px)]">
              <table className="capitalize w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Designation</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.map((sub: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{sub?.name}</td>
                      <td data-cell="Designation ">{sub?.designation}</td>
                      <td>
                        <button
                          onClick={() =>
                            fetch(
                              `https://dev.api.employees.aquila360.com/reportsAndCertificates/getInternCert?userid=${sub?.userId}`,
                              {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${accessToken}`,
                                },
                              }
                            )
                              .then((response) => {
                                if (!response.ok) {
                                  throw new Error(
                                    "Network response was not ok"
                                  );
                                }
                                return response.blob();
                              })
                              .then((blob) => {
                                console.log("Blob:", blob);
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                  "download",
                                  "InternCert.docx"
                                );
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              })
                              .catch((error) => {
                                console.error("Fetch error:", error);
                                alert(
                                  "Failed to download the certificate. Please try again later."
                                );
                              })
                          }
                          className={`text-[10px] w-[100px] btnReject`}
                        >
                          {"Download"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no data available" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No User Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default InternCertificate;
