import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { BriefcaseIcon } from "@heroicons/react/24/solid";
import WorkOnHolidayTable from "../../components/Table/WorkOnHolidayTable";
import {
  fetchAllWOHRequests,
  fetchWOHEmpCount,
  fetchWOHRequests,
} from "../../services/workOnHoliday.service";
import {
  IWOH,
  IWOHEmpCount,
  IWOHRequests,
} from "../../types/interfaces/IWOH.interface";

const WorkOnHoliday = () => {
  const [requestsCount, setRequestsCount] = useState<IWOHRequests>();
  const [WOHRequest, setWOHRequest] = useState<IWOH>({
    WorkOnHolidays: [],
    pendingCount:0
  });
  const [empCount, setEmpCount] = useState<IWOHEmpCount>({
    todayWorkOnHolidayCount: 0,
  });
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const getWOHRequests = async () => {
    const res = await fetchWOHRequests();
    if (res?.res) {
      setRequestsCount(res?.res as IWOHRequests);
    }
  };
  const getWOHEmpCount = async () => {
    const res = await fetchWOHEmpCount();
    if (res?.res) {
      setEmpCount(res?.res as IWOHEmpCount);
    }
  };

  const getAllWOHRequests = async (
    month?: string,
    year?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllWOHRequests(month, year, searchQuery);
    if (res?.res) {
      setWOHRequest(res?.res as IWOH);
    }
  };


  useEffect(() => {
    getWOHRequests();
    getWOHEmpCount();
  }, []);
  return (
    <div  className="basis-full px-[15px] xl:px-[30px]"
      onClick={() => {
        openPopoverId && openPopoverId?.length > 0 && setOpenPopoverId(null);
      }}
    >
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div>
          <EmployeeStatus
            showFirst={true}
            showSecond={true}
            heading="Work On Holiday"
            leadText="Manage work on holiday of employees"
            StatusLogo={<BriefcaseIcon className="w-9 h-9 text-[#33475F]" />}
            Subscription="Employees working off-day"
            price={empCount.todayWorkOnHolidayCount}
            TotalSubscriptions="Pending Requests"
            Total={WOHRequest.pendingCount}
          />
          <div className="basis-2/3">
            <WorkOnHolidayTable
              getWOHCountInfo={getWOHRequests}
              openPopoverId={openPopoverId}
              setOpenPopoverId={setOpenPopoverId}
              getAllWOHRequests={getAllWOHRequests}
              WOHRequest={WOHRequest}

            />
          </div>
        </div>
      </div>
  );
};

export default WorkOnHoliday;
