import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import {
  fetchAppraisalHistory,
  fetchUserWithEmail,
} from "../../services/empReports.service";
import { IReportUserData } from "../../types/IReportUser";
import { utils, writeFile } from "xlsx";
import { formatAmount } from "../../utils/dateFromatter";

interface Props {
  getUserWithEmail: () => void;
}

const ReportTable: React.FC<Props> = ({ getUserWithEmail }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState<IReportUserData[]>([]);
  const [reqAgain, setReqAgain] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];

  let timeoutId: NodeJS.Timeout | null = null;
  const currency = localStorage.getItem("currency");


  const getAllUser = async () => {
    const res = await fetchUserWithEmail();
    if (res?.res) {
      setUserData(res.res as IReportUserData[]);
    }
  };

  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAllUser();
      }, 500);
    } else {
      getAllUser();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  const handleDownloadHistoryAppraisalExcel = async (email: string) => {
    try {
      const res: any = await fetchAppraisalHistory(email);
      if (res?.res) {
        const employeeData = res.res || {};
        const contracts = employeeData.contracts || [];
        const exportToXLSX = (filename: string, data: any) => {
          const sheetName = "Report";
          const workBook = utils.book_new();
          const worksheet = utils.json_to_sheet(data);
          worksheet["!cols"] = [
            // { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
          ];
          utils.book_append_sheet(workBook, worksheet, sheetName);
          writeFile(workBook, `${filename}.xlsx`);
        };

        const saveAsXlsx = () => {
          exportToXLSX(
            "Report",
            contracts.map((contract: any, index: number) => ({
              // "Sr No": index === 0 ? 1 : "",
              // "Employee Id": employeeData.empId,
              Name: index === 0 ? employeeData.name : "",
              Status: index === 0 ? employeeData.status : "",
              Amount: currency + " " +  formatAmount( contract.amount),
              Designation: contract.designation,
              Since: contract.since,
              Till: contract.till,
            }))
          );
        };
        saveAsXlsx();
        // Transform the data
        // const transformedData = contracts.map(
        //   (contract: any, index: number) => ({
        //     "Sr No": index === 0 ? 1 : "",
        //     "Employee Id": employeeData.empId,
        //     Name: index === 0 ? employeeData.name : "",
        //     Status: index === 0 ? employeeData.status : "",
        //     Amount: contract.amount,
        //     Designation: contract.designation,
        //     Since: contract.since,
        //     Till: contract.till,
        //   })
        // );

        // Create the worksheet
        // const worksheet = XLSX.utils.json_to_sheet(transformedData);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Contracts");

        // // Write the Excel file
        // const excelBuffer = XLSX.write(workbook, {
        //   bookType: "xlsx",
        //   type: "array",
        // });

        // Create a Blob and initiate a download
        // const blob = new Blob([excelBuffer], {
        //   type: "application/octet-stream",
        // });
        // const blobUrl = window.URL.createObjectURL(blob);
        // const anchor = document.createElement("a");
        // anchor.href = blobUrl;
        // anchor.download = `Contracts-${new Date().getFullYear()}.xlsx`;
        // anchor.click();
        // window.URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Contracts data not found.");
        alert("Contracts data not found.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Something went wrong");
    }
  };

  const filteredReports = userData.filter((report: IReportUserData) =>
    report.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="py-5 my-3">
      <div className="relative flex md:flex-row flex-col md:items-center md:justify-end mb-3 md:mb-6 gap-3">
        {/* <input
          className="w-full md:max-w-[310px] py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#EBEBEB] rounded-lg focus:outline-none placeholder:text-sm"
          type="search"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <span className="absolute top-3 left-3">
          <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
        </span> */}
        <button
          onClick={getUserWithEmail}
          className="btnExport  text-white rounded px-3 py-3 font-medium"
        >
          Download All Perk History
        </button>
      </div>
      {filteredReports.length > 0 ? (

        <div className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-360px)]">
          <table className="w-full">
            <thead className="sticky top-0">
              <tr>
                <th></th>
                <th>Employee Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {filteredReports.map((sub, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td data-cell="Employee Name" className="capitalize">{sub.name}</td>
                  <td data-cell="Email">{sub.email}</td>
                  <td data-cell="Status">{sub.status}</td>
                  <td>
                    <button
                      onClick={() =>
                        handleDownloadHistoryAppraisalExcel(sub.email)
                      }
                      className="text-[10px] w-[100px] btnReject"
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center flex flex-col justify-center mt-16">
          <span className="text-center flex justify-center mb-3">
            <img src="/images/no-data.png" alt="no data available" />
          </span>
          <p className="text-sm font-normal text-[#5C5C5C]">
            No User Available.
          </p>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
