import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import {
  Squares2X2Icon,
  UserGroupIcon,
  XMarkIcon,
  AcademicCapIcon,
  ArrowTopRightOnSquareIcon,
  BanknotesIcon,
  BeakerIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  InboxIcon,
  NewspaperIcon,
  PresentationChartBarIcon,
  Square3Stack3DIcon,
  CurrencyRupeeIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

interface SidebarItem {
  id: string;
  path: string;
  label: string;
  icon: any;
}

interface Props {
  handleButtonClick?: () => void;
}

const SideBar: React.FC<Props> = ({ handleButtonClick }) => {
  const location = useLocation();
  const isItemSelected = (path: string) => {
    console.log(
      'loc',location.pathname === path
    )
    return location.pathname === path
  };

  // State for sidebar items
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([
    { id: "dashboard", path: "/", label: "Dashboard", icon: Squares2X2Icon },
    { id: "employee-management", path: "/employee-management", label: "Employees", icon: UserGroupIcon },
    { id: "employee-performance", path: "/employee-performance", label: "Performance", icon: PresentationChartBarIcon },
    { id: "leave-management", path: "/leave-management", label: "Leave", icon: CalendarDaysIcon },
    { id: "medical-allowance", path: "/medical-allowance", label: "Medical Allowance", icon: BuildingOfficeIcon },
    { id: "lunch-management", path: "/lunch-management", label: "Lunch Subscription", icon: InboxIcon },
    { id: "bonus", path: "/bonus", label: "Bonus", icon: CurrencyDollarIcon },

    { id: "advance-salary", path: "/advance-salary", label: "Advance Salary", icon: BanknotesIcon },
    { id: "asset-requisition", path: "/asset-requisition", label: "Asset Requisition", icon: Square3Stack3DIcon },
    { id: "work-on-holiday", path: "/work-on-holiday", label: "Work on Holiday", icon: BriefcaseIcon },
    { id: "payslips", path: "/payslips", label: "Payroll", icon: DocumentTextIcon },
    { id: "fuel-allowance", path: "/fuel-allowance", label: "Fuel Allowance", icon: BeakerIcon },
    { id: "employee-settlement", path: "/employee-settlement", label: "Leave Encashment", icon: CalculatorIcon },
    { id: "emp-schooling", path: "/emp-schooling", label: "Schooling", icon: AcademicCapIcon },
    { id: "emp-suggestion", path: "/emp-suggestion", label: "Suggestions", icon: ChatBubbleLeftEllipsisIcon },
    { id: "emp-report", path: "/emp-report", label: "Reports", icon: ClipboardDocumentListIcon },
    { id: "emp-certificates", path: "/emp-certificates", label: "Certificates and Letters", icon: NewspaperIcon },
    { id: "emp-detail", path: "/emp-detail", label: "Details", icon: ArrowTopRightOnSquareIcon },
  ]);




  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(sidebarItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSidebarItems(items); // Update the state with the new order
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="xl:hidden flex items-center justify-between max-w-[385px] bg-white rounded-tr-[40px] ps-[20px] pb-3 sm:ps-[30px] pt-3 pr-4">
        <img src="/images/weblogo.svg" alt="web logo" />
        <button onClick={handleButtonClick} className="close-btnn xl:hidden">
          <XMarkIcon className="w-6 h-6 text-[#333333]" />
        </button>
      </div>
      <div className="siderbar bg-[#33475F] flex flex-col items-start xl:rounded-tr-[40px] ps-[20px] sm:ps-[30px] pt-10 pr-5 relative z-50 max-w-[340px]">
        <Droppable droppableId="sidebar">
          {(provided : any) => (
            <ul
              className="siderbarulist w-full space-y-1"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sidebarItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided : any, snapshot : any)  => (
                    <li
                     className={`${
              isItemSelected(item.path) ? "bg-[#47596F]" : "transparent"
            }  px-[10px] py-3 rounded-xl hover:bg-[#47596F] cursor-pointer`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      
                    >
                      <Link
                        to={item.path}
                        className={`text-base text-white flex items-center gap-2 ${
                          location.pathname === item.path ? "font-semibold" : ""
                        }`}
                      >
                        <item.icon className="text-white w-5 h-5" />
                        {item.label}
                      </Link>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        {/* Other sidebar content */}
      </div>
    </DragDropContext>
  );
};

// Styles for draggable items
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties | undefined
): React.CSSProperties => ({
  userSelect: "none",
  background: isDragging ? "#47596F" : "",

  ...draggableStyle,
});

export default SideBar;
