import React, { useEffect, useRef, useState } from "react";
import {
  ArrowUpTrayIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import {
  CalendarDaysIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import ViewAttachments from "../Modals/ViewAttachments";
import TablePagination from "../pagination/TablePagination";
import ReactDatePicker from "react-datepicker";
import {
  exportMedicalCSV,
  fetchMedicalRequests,
  updateMedicalPaymentStatus,
  updateMedicalRequestStatus,
} from "../../services/medicalAllowance.service";
import {
  IMedicalAllowance,
  MedicalRequest,
} from "../../types/interfaces/IMedicalAllowance.interface";
import {
  formatDateSlash,
  formatDateSpace,
  formatAmount,
} from "../../utils/dateFromatter";
import moment from "moment";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import ApproveWithAmount from "../Modals/ApproveWithAmount";
import { toast } from "react-toastify";
import { scrollToTop } from "../../utils/tableScroller";
import * as XLSX from "xlsx";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface Props {
  getMedicalAllowanceInfo: () => void;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  getMedicalRequests: Function;
  medicalAllowanceRequests: IMedicalAllowance;
}

const MedicalAllowancTable: React.FC<Props> = ({
  getMedicalAllowanceInfo,
  openPopoverId,
  setOpenPopoverId,
  getMedicalRequests,
  medicalAllowanceRequests,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [maxAmount, setMaxAmount] = useState<number | undefined>(undefined);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [reqAgain, setReqAgain] = useState(false);

  const [comments, setComments] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<MedicalRequest>();
  const [actionToPerform, setActionToPerform] = useState("");
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [startDate, endDate] = dateRange?.length > 0 ? dateRange : [];
  // const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);
  const [showPayModal, setShowPayModal] = useState<boolean>(false);
  const [cuurentPayId, setCurrentPayId] = useState<string>("");
  const [paymentStatus, setPaymentStatus] = useState<any>("");

  const ref: any = useRef();
  useOnClickOutside(ref, () => setShowPayModal(false));

  const handlePaymentModel = (id: string) => {
    setShowPayModal(true);
    setCurrentPayId(id);
  };

  const openModal = (event: any, record: MedicalRequest) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const handlePayment = async () => {
    const resp = await updateMedicalPaymentStatus(cuurentPayId);
    if (resp) {
      setPaymentStatus(resp);
      setShowPayModal(false);
      toast.success("Payment Done Sucessfully");
    }
  };

  const handleMedicalRequestAction = async (amount: number) => {
    if (!comments) {
      toast.error("Please enter some comments");
      return;
    }
    const reqBody = {
      comment: comments,
      approvedAmount: amount,
    };
    const res = await updateMedicalRequestStatus(
      selectedRecord?.medicalRequest?.ID as string,
      actionToPerform,
      reqBody
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      getMedicalAllowanceInfo();
      toast.success(res?.res as string);
      closeModal();
    }
  };
  // const handleExportCSV = async () => {
  //   try {
  //     // Fetch data from the API
  //     const res: any = await fetchMedicalRequests();

  //     // Check if the response contains data
  //     if (res?.res?.medicalRequests) {
  //       // Convert the data to CSV format using the helper function
  //       const csvData = convertToCSV(res.res.medicalRequests);

  //       // Create a Blob from the CSV data
  //       const blob = new Blob([csvData], { type: "text/csv" });
  //       const blobUrl = window.URL.createObjectURL(blob);

  //       // Create an anchor element and trigger the download
  //       const anchor = document.createElement("a");
  //       anchor.href = blobUrl;
  //       anchor.download = `Medical-Allowance-${new Date().getFullYear()}.csv`;
  //       anchor.click();

  //       // Revoke the blob URL
  //       window.URL.revokeObjectURL(blobUrl);

  //       // Notify the user about the successful download (optional)
  //       toast.success("CSV file downloaded successfully!");
  //     } else {
  //       throw new Error("No data found");
  //     }
  //   } catch (error) {
  //     console.error("Error exporting CSV:", error);
  //     toast.error("Failed to download CSV file.");
  //   }
  // };

  // // Helper function to convert JSON data to CSV format
  // const convertToCSV = (data: any[]) => {
  //   if (!data || !data.length) return "";

  //   const headers = Object.keys(data[0]);
  //   const csvRows = data.map((row) =>
  //     headers.map((header) => JSON.stringify(row[header] || "")).join(",")
  //   );

  //   return [headers.join(","), ...csvRows].join("\n");
  // };
  const handleExportCSV = async () => {
    const res: any = await fetchMedicalRequests();

    if (res?.res) {
      const data = res.res.medicalRequests || [];
      console.log("Data", data)

      const exportToXLSX = (
        filename: string,
        data: any[],
        sheetName: string
      ) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          // { wch: 10 }, // Adjust column widths as needed
          { wch: 35 },
          { wch: 35 },
          { wch: 20 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          {wch: 10},
          {wch: 10},
          { wch: 20 },
          { wch: 20 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any[]) => {
        return data.map((row, index) => {
          const {
            PatientName,
            PharmacyName,
            PatientRelation,
            PurchaseDate,
            AmountApplied,
            AmountApproved,
            Status,
            PaymentStatus,
            Comment,
            Description,
          } = row.medicalRequest;

          return {
            // "Sr. No": index + 1,
            "Beneficiary Name": row.userName,
            "Patient Name": PatientName,
            "Pharmacy Name": PharmacyName,
            "Patient Relation": PatientRelation,
            "Purchase Date": PurchaseDate,
            "Amount Applied": currency + " " +  formatAmount(AmountApplied),
            "Amount Approved": formatAmount(AmountApproved),
            "Status":Status,
            "Paid": PaymentStatus,
            Description: Description,
            Comment: Comment || "-",

          };
        });
      };

      const saveAsXlsx = () => {
        const formattedData = processRows(data);
        exportToXLSX("MedicalData", formattedData, "Medical Data");
      };

      saveAsXlsx();
    } else {
      // Handle error or empty response
      console.error("Error fetching medical requests:", res.error);
      // toast.error("Failed to fetch medical requests."); // Optionally use toast to show an error message
    }
  };
  const handleComments = (comments: string) => {
    setComments(comments);
  };
  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getMedicalRequests(
            formatDateSlash(startDate),
            formatDateSlash(endDate),
            searchQuery
          );
        } else getMedicalRequests(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getMedicalRequests(formatDateSlash(startDate), formatDateSlash(endDate));
    } else {
      getMedicalRequests();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery, paymentStatus]);
  // for file export from healthcare
  // const handleDownloadFilePattern = async () => {
  //   const res: any = await getFilePattern(viewOption as string);
  //   if (res?.url) {
  //     window.open(res?.url, "_blank");
  //     console.log("check file: ", res);
  //   } else {
  //     store.dispatch(setSnackBar("error"));
  //     store.dispatch(
  //       setToastMessage("Sorry, couldn't download template file.")
  //     );
  //   }
  // };
  const openViewModal = (record: MedicalRequest) => {
    setIsViewModalOpen(true);
    setSelectedRecord(record);
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setMaxAmount(undefined);
  };

  const openApproveModal = (action: string, record: MedicalRequest) => {
    setIsModalOpen(true);
    // setMaxAmount(record.medicalRequest.AmountApplied);
    setActionToPerform(action);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const togglePopover = (record: MedicalRequest) => {
    if (openPopoverId === record.medicalRequest.ID) {
      setOpenPopoverId(null);
    } else {
      setOpenPopoverId(record.medicalRequest.ID);
    }
    setSelectedRecord(record);
  };
  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };
  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);
  const currency = localStorage.getItem("currency");

  const handleKeyDown = (e:any ) => {
    e.preventDefault(); // Prevent all key presses
  };
  
  return (
    <>
      <div className="py-5 ">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3 w-[99%]">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center gap-1 sm:gap-2 z-10">
            <ReactDatePicker
              className="cursor-pointer rounded-lg md:min-w-[230px] max-w-[230px] bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              onChange={(date) => {
                setDateRange(date as Date[]);
              }}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select Range"
              selectsRange={true}
              dateFormat="dd/MM/yyyy"
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}

            />
            {endDate ? (
              <button
                className="absolute top-3 right-4 items-center"
                onClick={handleClear}
              >
                <XMarkIcon className="text-black w-5 h-5  right-4" />
              </button>
            ) : (
              <span className="absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
          <button
            className="btnExport text-xs min-w-[85px] font-semibold text-[#378EB5] flex items-center w-[107px] ml-auto gap-1 bg-[rgb(55,142,181)] text-white py-[9px] px-[10px]"
            onClick={() => handleExportCSV()}
          >
            <ArrowUpTrayIcon className="w-4 h-4" />
            Export CSV
          </button>
        </div>

        {medicalAllowanceRequests?.medicalRequests?.length > 0 ? (
          <>
            <div
              className="tablecontainer overflow-x-scroll w-[calc(100dvw-30px)] xl:w-[calc(100dvw-366px)] whitespace-nowrap Employees  overflow-y-scroll h-[calc(100vh-325px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize medicalAllowance w-full">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Billed To</th>
                    <th>Relationship</th>
                    <th>Invoice Date</th>
                    <th>Req. Amount</th>
                    <th>App. Amount</th>
                    <th>Reason</th>
                    <th>Payment</th>

                    <th>Created At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {medicalAllowanceRequests?.medicalRequests?.map(
                    (req, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td data-cell="Employee Name" className="flex gap-2">
                          <button
                            className={` text-[#378EB5] text-[10px] font-semibold ${
                              !req?.medicalRequest?.Attachments && "opacity-50 "
                            }`}
                            onClick={() => openViewModal(req)}
                            disabled={!req?.medicalRequest?.Attachments}
                          >
                            <PhotoIcon className="w-4 h-4 text-[#378EB5]" />
                          </button>
                          {req?.userName}
                        </td>
                        <td data-cell="Hospital/Pharmacy">
                          {req?.medicalRequest?.PharmacyName}
                        </td>
                        <td data-cell="Selected Person">
                          {req?.medicalRequest?.PatientRelation}
                        </td>
                        <td data-cell="Purchase Date">
                          {formatDateSpace(req?.medicalRequest?.PurchaseDate)}
                        </td>

                        <td data-cell="Amount Applied">
                          {currency}{" "}
                          {formatAmount(req?.medicalRequest?.AmountApplied)}
                        </td>
                        <td data-cell="Amount Approved">
                          {currency} {""}
                          {formatAmount(req?.medicalRequest?.AmountApproved)}
                        </td>
                        <td data-cell="Reason">
                          <div className="relative">
                            <button
                              onClick={() => togglePopover(req)}
                              className={`text-sm text-[#378EB5] font-semibold ${
                                openPopoverId &&
                                openPopoverId?.length > 0 &&
                                (openPopoverId === req.medicalRequest.ID
                                  ? ""
                                  : "cursor-default")
                              }`}
                            >
                              <ChatBubbleBottomCenterTextIcon className="text-#378EB5 w-5 h-5" />
                            </button>
                            {/* {isPopoverOpen &&
                              selectedRecord?.medicalRequest.ID ===
                                req.medicalRequest.ID && (
                                <ReasonPopOver
                                  reason={
                                    selectedRecord?.medicalRequest
                                      ?.Description as string
                                  }
                                />
                              )} */}
                            {openPopoverId === req.medicalRequest.ID && (
                              // <div
                              //   className=" h-screen w-screen"
                              //   onClick={() => {
                              //     setOpenPopoverId(null);
                              //   }}
                              // >
                              <ReasonPopOver
                                reason={
                                  req.medicalRequest.Description as string
                                }
                                onClose={() => setOpenPopoverId(null)}
                              />
                              // </div>
                            )}
                          </div>
                        </td>
                        <td>{req.medicalRequest.PaymentStatus}</td>
                        <td data-cell="Applied Date">
                          {formatDateSpace(
                            moment(req?.medicalRequest?.CreatedAt).format(
                              "DD/MM/YYYY"
                            )
                          )}
                        </td>
                        <td>
                          <div className="flex items-center justify-end gap-1">
                            {req?.medicalRequest?.PaymentStatus === "Unpaid" ? (
                              <>
                                <button
                                  className="btnApprove"
                                  name="paid"
                                  onClick={() =>
                                    handlePaymentModel(req.medicalRequest.ID)
                                  }
                                >
                                  Pay
                                </button>
                              </>
                            ) : (
                              ""
                            )}

                            {req?.medicalRequest?.Status === "pending" ? (
                              <>
                                <button
                                  className="btnApprove"
                                  name="accepted"
                                  onClick={(event) => openModal(event, req)}
                                >
                                  Approve
                                </button>
                                <button
                                  className="btnReject"
                                  name="rejected"
                                  onClick={(event) => openModal(event, req)}
                                >
                                  Reject
                                </button>
                              </>
                            ) : req?.medicalRequest?.Status === "accepted" ? (
                              <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                                Approved
                              </span>
                            ) : (
                              <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                                Rejected
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            {/* <TablePagination /> */}
            <ViewAttachments
              isOpen={isViewModalOpen}
              onClose={closeViewModal}
              attachments={
                selectedRecord?.medicalRequest?.Attachments as string[]
              }
            />

            <ApproveWithAmount
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              actionToPerform={actionToPerform}
              sendRequest={handleMedicalRequestAction}
              record={selectedRecord}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Medical Request Available.
            </p>
          </div>
        )}
      </div>
      {showPayModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={ref} className="bg-white rounded-lg p-5  w-[365px]">
            <h2 className="text-lg font-semibold mb-4">Confirm Payment </h2>
            <p className="mb-4">Are you sure you want to pay?</p>
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-gray-700 rounded px-4 py-2"
                onClick={() => setShowPayModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#378EB5] text-white rounded px-4 py-2"
                onClick={handlePayment}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MedicalAllowancTable;
