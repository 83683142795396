import { useEffect, useRef, useState, ReactNode } from "react";
import { IoMdClose } from "react-icons/io";

interface MenuModalProps {
  openModal: boolean;
  closeModal: () => void;
  children: ReactNode;
}

function MenuModal({ openModal, closeModal, children }: MenuModalProps) {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (openModal) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
      setTimeout(() => {
      }, 300);
    }
  }, [openModal]);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
  //       closeModal();
  //     }
  //   };

  //   if (openModal) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [openModal, closeModal]);

  return (
    <div
      className={`${
        openModal ? "fixed inset-0 w-full h-full bg-black bg-opacity-50 z-50" : "hidden"
      } transition-all duration-300`}
    >
      <div
        ref={contentRef}
        className={`w-[800px] ml-auto mr-0 fixed top-0 bottom-0 right-0 h-screen my-0 bg-white shadow-2xl transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        {children}
        <button onClick={closeModal} className="absolute right-3 top-4 text-2xl">
          <IoMdClose />
        </button>
      </div>
    </div>
  );
}

export default MenuModal;
