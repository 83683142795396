import { api } from "../utils/axios";

export const fetchTotalSchooling = async (
  month? :string , 
  year? : string
) => {
  try {
    const { data, ok } = await api.get(`/schooling/getAllReqs${
      month && year ? `?month=${month}&year=${year}` : ""
    }`);
    if (ok) {
      // delteData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateSchoolingStatus = async (
  id :string , 
  status : string,
  comment : string
) => {
  const formData = {
    "schoolingId" : id , 
    "status" : status ,
    "comment": comment

  }
  try {
    const { data, ok } = await api.patch(`/schooling/respondReq` , formData);
    if (ok) {
      // delteData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};



export const updateSchoolingPayment = async (
  id :string , 

) => {
 
  try {
    const { data, ok } = await api.patch(`/schooling/markSchoolingAsPaid/${id}`);
    if (ok) {
      // delteData$.next(true);
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};



