import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import EmployeeStatus from "../../components/Employeestatus/EmployeeStatus";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import TotalEmployees from "../../components/totalEmployees/TotalEmployees";
import EmployeeInformation from "../../components/employeeInformations/EmployeeInformation";
import { fetchAllEmployees, resfresh$ } from "../../services/employeeManagement.service";
import {
  IEmployeesManagement,
  User,
} from "../../types/interfaces/IEmployeesManagement.interface";

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState<IEmployeesManagement>({
    users: [],
  });
  const [selectedEmployee, setSelectedEmployee] = useState<User>();
  const [numOfEmps, setNumOfEmps] = useState(0);

  const getAllEmployees = async () => {
    const res = await fetchAllEmployees();
    if (res?.res) {
      setEmployees(res?.res as IEmployeesManagement);
    }
  };

  useEffect(() => {
    if (employees?.users?.length < 1) return;
    setNumOfEmps(employees?.users?.length);
  }, [employees]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <>
      {/* <Header />
      <div className="flex flex-row">
        <div className="xl:basis-[385px] xl:block hidden">
          <SideBar />
        </div> */}
        <div className="basis-full px-[15px] xl:px-[30px]">
          <EmployeeStatus
            heading="Employees"
            leadText="Manage employees here"
            StatusLogo={<UserGroupIcon className="w-9 h-9 text-[#33475F]" />}
            Subscription="Total Employees"
            price={numOfEmps}
            showFirst={true}
            showSecond={false}
          />
          <div className="basis-full md:basis-2/3">
            <div className="flex flex-col md:flex-row mt-5 gap-3 xl:gap-5">
              <div className="basis-full md:basis-1/3">
                <TotalEmployees
                  employees={employees as IEmployeesManagement}
                  selectedEmployee={selectedEmployee as User}
                  setSelectedEmployee={setSelectedEmployee}
                />
              </div>
              <div className="basis-full md:basis-2/3">
                <EmployeeInformation
                  selectedEmployee={selectedEmployee as User}
                  setSelectedEmployee={setSelectedEmployee}
                  getAllEmployees={getAllEmployees}
                />
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default EmployeeManagement;
